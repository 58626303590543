import * as React from "react";
import { useContext, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { AppContextInterface, AppCtx } from "../App";

export interface EmailNotesProps {
  closeHandler: (email?: string, optIn?: boolean, termsAgree?: boolean) => void;
  termsUrl: string;
}

export const EmailNotes = (props: EmailNotesProps) => {
  const { configuration, appState } = useContext(AppCtx) as AppContextInterface;
  const EMAIL_INTERFACE = configuration?.screens?.save_default;
  const emailInputRef = useRef<HTMLInputElement>(null);
  const [emailValid, setEmailValid] = useState(true);
  const [customerEmail, setCustomerEmail] = useState(appState?.customer?.email);
  const [optIn, setOptIn] = useState(
    appState?.customer?.sign_up_for_email_list || false
  );
  const handleEmailChange = (e: any) => {
    setCustomerEmail(e.target.value);
    setEmailValid(e.target.value === "" || !!e?.target?.validity?.valid);
  };
  return (
    <div className="d-flex flex-column align-items-center gap-3 px-2 email-notes">
      <span className="small text-center">&nbsp;</span>
      <h4 className="text-center subtitle">{EMAIL_INTERFACE?.title}</h4>

      {!appState?.customer?.email && (
        <Form
          noValidate
          className="d-flex flex-column gap-2 align-items-center send-form"
        >
          <Form.Control
            ref={emailInputRef}
            className="email-input input"
            type="email"
            name="email"
            placeholder="you@email.com"
            value={customerEmail || ""}
            required
            onChange={handleEmailChange}
          ></Form.Control>

          <Form.Check
            className="d-flex flex-row"
            type="checkbox"
            checked={optIn}
            onChange={(e) => {
              setOptIn(!!e.target.checked);
            }}
            label="Sign up to recieve email updates on exclusive offers, product announcements,sales and more"
          ></Form.Check>
        </Form>
      )}
      <Button
        className="cta-button px-4"
        disabled={!emailValid}
        onClick={() => {
          props.closeHandler(
            customerEmail,
            optIn,
            appState?.customer?.accepted_terms || false
          );
        }}
      >
        EMAIL ME MY NOTES
      </Button>
      <Button
        variant="link"
        className="link"
        onClick={() => {
          props.closeHandler(
            "",
            optIn,
            appState?.customer?.accepted_terms || false
          );
        }}
      >
        Skip
      </Button>
      {/* <div
        className="link"
        onClick={() => {
          Analytics.push("email_skipped", {
            wines_completed: Analytics.tastingsCompleted(tastingContent.wines),
          });
          props.closeHandler();
        }}
      >
        Skip
      </div> */}
    </div>
  );
};
