import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import "./App.scss";
import { Route, Routes, useLocation } from "react-router-dom";
import { TastingRouteWrapper } from "./TastingRouteWrapper";
import LandingPage from "./Pages/LandingPage";
import CreateTastingRoomPage from "./Pages/CreateTastingRoom";
import WineSelectionPage from "./Pages/WineSelectionPage";
import { AromaSelectionPage } from "./Pages/AromaSelectionPage";
import { TasteSelectionPage } from "./Pages/TasteSelectionPage";
import { MemorySelectionPage } from "./Pages/MemorySelectionPage";
import { AddNotePage } from "./Pages/AddNotePage";
import { AnimatePresence } from "framer-motion";
import useAxios from "./hooks/useAxios";
import axiosTasting from "./api/tasting";
import { Configuration } from "./models/configuration";
import { GalloSession } from "./models/gallo-session";
import useAxiosFunction from "./hooks/useAxiosFunction";
import { AppState } from "./models/app-state";
import { Wine } from "./models/wine";
import { Customer } from "./models/customer";
import TastingNotePage from "./Pages/TastingNotePage";
import { TastingContent } from "./models/tasting-content";
import { RoomHeader } from "./Components/RoomHeader";
import WineMakerTastingNotePage from "./Pages/WineMakerTastingNotePage";
import HowItWorks from "./Pages/HowItWorks";
import * as Constants from "./utilities/Contants";

export interface AppContextInterface {
  configuration: Configuration | null;
  loadConfiguration: Function;
  galloSession: GalloSession;
  tastingContent: TastingContent;
  setTastingContent: Dispatch<SetStateAction<TastingContent>>;
  appState: AppState;
  setAppState: Dispatch<SetStateAction<AppState>>;
}

export const AppCtx = createContext<AppContextInterface | null>(null);

function App() {
  const location = useLocation();
  const [tastingContentResponse, error, loading, axiosFetch] =
    useAxiosFunction();
  const [configuration, setConfiguration] = useState<Configuration | null>(
    localStorage.getItem(Constants.LOCALSTORAGE_CONFIG)
      ? JSON.parse(
          localStorage.getItem(Constants.LOCALSTORAGE_CONFIG) || "null"
        )
      : null
  );
  const [galloSession, setGalloSession] = useState<GalloSession>({
    gtin: localStorage.getItem(Constants.LOCALSTORAGE_GTIN) || "",
    serial: localStorage.getItem(Constants.LOCALSTORAGE_SERIAL) || "",
    token: localStorage.getItem(Constants.LOCALSTORAGE_TOKEN) || "",
    type: localStorage.getItem(Constants.LOCALSTORAGE_TYPE) || "",
    wineId: parseInt(
      localStorage.getItem(Constants.LOCALSTORAGE_WINEID) || "0"
    ),
    eventId: localStorage.getItem(Constants.LOCALSTORAGE_EVENTID) || "",
  });

  const [appState, setAppState] = useState<AppState>(
    localStorage.getItem(Constants.LOCALSTORAGE_APPSTATE)
      ? JSON.parse(
          localStorage.getItem(Constants.LOCALSTORAGE_APPSTATE) as string
        )
      : {
          customer: {
            name: "",
            email: "",
            sign_up_for_email_list: false,
            accepted_terms: false,
            sawHowTo: false,
          },
          tasting: { wine_id: 0, accepted_terms: false },
        }
  );
  const [tastingContent, setTastingContent] = useState<TastingContent>(
    localStorage.getItem(Constants.LOCALSTORAGE_CONTENT)
      ? JSON.parse(
          localStorage.getItem(Constants.LOCALSTORAGE_CONTENT) || "null"
        )
      : {
          wines: [],
          customer: { email: "", name: "", sawHowTo: false },
          tasting_menu: { name: "" },
          accepted_terms: false,
        }
  );

  /* const [config, configError] = useAxios({
    axiosInstance: axiosTasting,
    method: "GET",
    url: "/configuration.json",
    requestConfig: {},
  }) as [Configuration, string, boolean, () => void]; */
  const [config, configError, loadingConfig, axiosFetchConfig] =
    useAxiosFunction();
  const loadConfig = () => {
    axiosFetchConfig({
      axiosInstance: axiosTasting,
      method: "GET",
      url: "/configuration.json",
      requestConfig: {},
    });
  };
  useEffect(() => {
    console.log("Load config effect");
    loadConfig();
  }, []);
  useEffect(() => {
    console.log("Updating localstorage app state...", appState);
    localStorage.setItem(
      Constants.LOCALSTORAGE_APPSTATE,
      JSON.stringify(appState)
    );
  }, [appState]);
  useEffect(() => {
    localStorage.setItem(
      Constants.LOCALSTORAGE_CONFIG,
      JSON.stringify(configuration)
    );
  }, [configuration]);
  useEffect(() => {
    localStorage.setItem(
      Constants.LOCALSTORAGE_CONTENT,
      JSON.stringify(tastingContent)
    );
  }, [tastingContent]);

  useEffect(() => {
    console.log("CONFIG:", config);
    setConfiguration(config as unknown as Configuration);
    if (!Array.isArray(config)) {
      axiosFetch({
        axiosInstance: axiosTasting,
        method: "GET",
        url: `?serialNumber=${galloSession.serial}&customer_cookie=${galloSession.token}`,
      })
        .then((response) => {
          console.log("Got tasting data...");
        })
        .catch((error) => {
          console.log("Error:", error);
        });
    }
  }, [config]);

  useEffect(() => {
    console.log("TASTING CONTENT:", tastingContentResponse);
    if (!Array.isArray(tastingContentResponse)) {
      setTastingContent(tastingContentResponse);
      const content: TastingContent = tastingContentResponse;
      setAppState((prev) => ({
        ...prev,
        customer: {
          ...content?.customer,
          accepted_terms: content?.accepted_terms,
        },
      }));
    }
  }, [tastingContentResponse]);

  useEffect(() => {
    console.log("Location effect applied");
    if (location.pathname === "/select") {
      console.log("location change: /select clearing tasting");
      setAppState(
        (prev: AppState) =>
          ({
            ...prev,
            tasting: { ...prev.tasting, wine_id: 0 },
          } as AppState)
      );
    }
    const query = new URLSearchParams(location.search);
    if (Array.from(query).length) {
      setGalloSession({
        gtin: query.get("gtin") || "",
        serial: query.get("serial") || query.get("serialNumber") || "",
        token: query.get("customer_cookie") || "",
        type: query.get("type") || "",
        wineId: parseInt(query.get("wine_id") || "0"),
        eventId: query.get("event") || query.get("eventId") || "",
      });
      localStorage.setItem(
        Constants.LOCALSTORAGE_GTIN,
        query.get("gtin") as string
      );
      localStorage.setItem(
        Constants.LOCALSTORAGE_SERIAL,
        query.get("serial") || (query.get("serialNumber") as string)
      );
      localStorage.setItem(
        Constants.LOCALSTORAGE_TOKEN,
        query.get("customer_cookie") as string
      );
      localStorage.setItem(
        Constants.LOCALSTORAGE_TYPE,
        query.get("type") as string
      );
      localStorage.setItem(
        Constants.LOCALSTORAGE_EVENTID,
        (query.get("event") || query.get("eventId")) as string
      );
      localStorage.setItem(
        Constants.LOCALSTORAGE_WINEID,
        query.get("wine_id") as string
      );
    } else {
      // No query params, try loading from local storage.
      setGalloSession({
        gtin: localStorage.getItem(Constants.LOCALSTORAGE_GTIN) || "",
        serial: localStorage.getItem(Constants.LOCALSTORAGE_SERIAL) || "",
        token: localStorage.getItem(Constants.LOCALSTORAGE_TOKEN) || "",
        type: localStorage.getItem(Constants.LOCALSTORAGE_TYPE) || "",
        wineId: parseInt(
          localStorage.getItem(Constants.LOCALSTORAGE_WINEID) || "0"
        ),
        eventId: localStorage.getItem(Constants.LOCALSTORAGE_EVENTID) || "",
      });
      console.log("Location change noquery, config:", configuration);
    }
  }, [location]);

  return (
    <AppCtx.Provider
      value={{
        configuration,
        galloSession,
        tastingContent,
        setTastingContent,
        appState,
        setAppState,
        loadConfiguration: loadConfig,
      }}
    >
      <div className="App">
        {location.pathname !== "/" &&
          location.pathname !== "/create" &&
          location.pathname !== "/tasting" &&
          location.pathname !== "/winemaker-note" && <RoomHeader />}
        <AnimatePresence exitBeforeEnter>
          <Routes location={location} key={location.pathname}>
            <Route element={<TastingRouteWrapper />}>
              <Route path="/" element={<LandingPage />} />
              <Route path="create" element={<CreateTastingRoomPage />} />
              <Route path="select" element={<WineSelectionPage />} />
              <Route path="how-it-works" element={<HowItWorks />} />
              <Route path="aroma" element={<AromaSelectionPage />} />
              <Route path="taste" element={<TasteSelectionPage />} />
              <Route path="memory" element={<MemorySelectionPage />} />
              <Route path="note" element={<AddNotePage />} />
              <Route path="tasting" element={<TastingNotePage />} />
              <Route
                path="winemaker-note"
                element={<WineMakerTastingNotePage />}
              />
            </Route>
          </Routes>
        </AnimatePresence>
      </div>
    </AppCtx.Provider>
  );
}

export default App;
