import * as React from "react";
import { useContext, useEffect, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { XCircleFill } from "react-bootstrap-icons";
import { AppContextInterface, AppCtx } from "../App";
import useAxiosFunction from "../hooks/useAxiosFunction";
import axiosTasting from "../api/tasting";
import { TastingNote } from "../models/tasting-note";

interface ShareNotesProps {
  allNotes: boolean;
  title: string;
  subtitle: string;
  handleClose: Function;
}

const ShareNotes: React.FunctionComponent<ShareNotesProps> = (props) => {
  const { configuration, galloSession, appState } = useContext(
    AppCtx
  ) as AppContextInterface;
  const [shareTastingResponse, shareTastingError, loading, axiosFetch] =
    useAxiosFunction();
  const senderEmailFieldRef = useRef(null);
  const recipientEmailFieldRef = useRef(null);
  const [senderEmail, setSenderEmail] = useState(
    appState?.customer?.email || ""
  );
  const [recipientEmail, setRecipientEmail] = useState();
  const [senderEmailValid, setSenderEmailValid] = useState(!!senderEmail);
  const [senderRecipientValid, setRecipientEmailValid] = useState(false);

  const handleSenderEmailChange = (e: any) => {
    setSenderEmail(e.target.value);
    setSenderEmailValid(!!e?.target?.validity?.valid);
  };

  const handleRecipientEmailChange = (e: any) => {
    setRecipientEmail(e.target.value);
    setRecipientEmailValid(!!e?.target?.validity?.valid);
  };

  const handleSend = () => {
    let tastingFormData = new FormData();
    tastingFormData.append("customer_cookie", galloSession.token);
    tastingFormData.append("serialNumber", galloSession.serial);
    tastingFormData.append("customer[email]", appState.customer.email);
    tastingFormData.append("email", recipientEmail || "");
    tastingFormData.append(
      "tasting[accepted_terms]",
      appState?.customer?.accepted_terms?.toString() || "false"
    );
    if (!props.allNotes) {
      // Share a specific tasting note.
      const tasting = appState?.tasting || {};
      Object.keys(tasting).forEach((key) => {
        let value = tasting[key as keyof TastingNote];
        if (value !== undefined) {
          value = typeof value == "string" ? value : value.toString();
          tastingFormData.append(
            `tasting[tasting_notes_attributes][0][${key}]`,
            value
          );
        }
      });
      console.log("FORM:", tastingFormData.entries());
    }

    axiosFetch({
      axiosInstance: axiosTasting,
      method: "POST",
      url: `/share?serialNumber=${galloSession.serial}&customer_cookie=${galloSession.token}`,
      body: tastingFormData,
      requestConfig: {
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      },
    });
  };

  useEffect(() => {
    console.log(
      "share response/error change:",
      shareTastingResponse,
      shareTastingError
    );
    if (!Array.isArray(shareTastingResponse) && !shareTastingError) {
      console.log("Share success:", shareTastingResponse);
      props.handleClose();
    } else if (shareTastingError) {
      console.log("Error sharing tasting:", shareTastingError);
    }
  }, [shareTastingResponse, shareTastingError]);

  return (
    <div className="d-flex flex-column align-items-center share-notes">
      <div className="d-flex flex-row justify-content-end header">
        <XCircleFill
          className="close"
          onClick={() => {
            props.handleClose();
          }}
        />
      </div>
      <span className="mt-3 title">{props.title}</span>
      <div
        className="d-flex flex-column align-items-center mt-3 subtitle"
        dangerouslySetInnerHTML={{
          __html: props.subtitle,
        }}
      ></div>
      <div className="d-flex flex-column gap-1 mx-1 px-3 pt-3 pb-2 mt-5 share-form-container">
        <Form noValidate className="send-form">
          <Form.Label className="small">Recipient&apos;s email</Form.Label>
          <Form.Control
            ref={recipientEmailFieldRef}
            className="email-input input"
            type="email"
            name="recipient_email"
            placeholder="friend@email.com"
            value={recipientEmail || ""}
            required
            onChange={handleRecipientEmailChange}
          ></Form.Control>
          {!senderEmail && (
            <>
              <Form.Label className="mt-2 small">Your email</Form.Label>
              <Form.Control
                ref={senderEmailFieldRef}
                className="email-input input"
                type="email"
                name="sender_email"
                placeholder="you@email.com"
                value={senderEmail}
                required
                onChange={handleSenderEmailChange}
              ></Form.Control>
            </>
          )}
          {/* <a
            className="d-block text-end mb-2 small"
            target="_blank"
            href={configuration?.privacy_policy || ""}
          >
            Privacy Policy
          </a> */}
        </Form>
        <Button
          className="mt-4 cta-button"
          disabled={!senderEmailValid}
          onClick={handleSend}
        >
          SEND
        </Button>
      </div>
    </div>
  );
};

export default ShareNotes;
