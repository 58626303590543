import * as React from "react";
import { TastingNote } from "../models/tasting-note";
import { Wine } from "../models/wine";

interface TastingSummaryProps {
  tasting: TastingNote | undefined;
  wine: Wine | undefined;
}

const TastingSummary: React.FunctionComponent<TastingSummaryProps> = (
  props
) => {
  const WIDTH = 260;
  const HEIGHT = 300;
  const PADX = 22;
  const PADY = 30;
  const memoryQuality = props.wine?.memory_qualities?.find(
    (q) => q.id == props.tasting?.memory_quality_id
  );
  const aromaX = props.tasting?.aroma_x || 50;
  const aromaY = props.tasting?.aroma_y || 50;
  console.log("ARomaxy:", aromaX, aromaY);
  const aroma = {
    q1: props.wine?.aroma_qualities?.find(
      (q) => q.id == props.tasting?.aroma_quality_1_id
    ),
    q2: props.wine?.aroma_qualities?.find(
      (q) => q.id == props.tasting?.aroma_quality_2_id
    ),
    q3: props.wine?.aroma_qualities?.find(
      (q) => q.id == props.tasting?.aroma_quality_3_id
    ),
    q4: props.wine?.aroma_qualities?.find(
      (q) => q.id == props.tasting?.aroma_quality_4_id
    ),
    x: (aromaX / 100) * (WIDTH + PADX),
    y: (aromaY / 100) * (HEIGHT + PADY),
    size1:
      (WIDTH / 100) * aromaX <= (HEIGHT / 100) * aromaY
        ? (WIDTH / 100) * aromaX - PADX
        : (HEIGHT / 100) * aromaY - PADY,
    size2:
      (WIDTH / 100) * (100 - aromaX) <= (HEIGHT / 100) * aromaY
        ? (WIDTH / 100) * (100 - aromaX)
        : (HEIGHT / 100) * aromaY + PADY,
    size3:
      (WIDTH / 100) * aromaX <= (HEIGHT / 100) * (100 - aromaY)
        ? (WIDTH / 100) * aromaX - PADX
        : (HEIGHT / 100) * (100 - aromaY) - PADY,
    size4:
      (WIDTH / 100) * (100 - aromaX) <= (HEIGHT / 100) * (100 - aromaY)
        ? (WIDTH / 100) * (100 - aromaX)
        : (HEIGHT / 100) * (100 - aromaY) - PADY,
  };
  console.log("AROMA:", aroma);

  const tasteX = props.tasting?.taste_x || 50;
  const tasteY = props.tasting?.taste_y || 50;
  console.log("Tastexy:", tasteX, tasteY);
  const taste = {
    q1: props.wine?.taste_qualities?.find(
      (q) => q.id == props.tasting?.taste_quality_1_id
    ),
    q2: props.wine?.taste_qualities?.find(
      (q) => q.id == props.tasting?.taste_quality_2_id
    ),
    q3: props.wine?.taste_qualities?.find(
      (q) => q.id == props.tasting?.taste_quality_3_id
    ),
    q4: props.wine?.taste_qualities?.find(
      (q) => q.id == props.tasting?.taste_quality_4_id
    ),
    x: (tasteX / 100) * (WIDTH + PADX),
    y: (tasteY / 100) * (HEIGHT + PADY),
    size1:
      (WIDTH / 100) * tasteX <= (HEIGHT / 100) * tasteY
        ? (WIDTH / 100) * tasteX - PADX
        : (HEIGHT / 100) * tasteY - PADY,
    size2:
      (WIDTH / 100) * (100 - tasteX) <= (HEIGHT / 100) * tasteY
        ? (WIDTH / 100) * (100 - tasteX)
        : (HEIGHT / 100) * tasteY + PADY,
    size3:
      (WIDTH / 100) * tasteX <= (HEIGHT / 100) * (100 - tasteY)
        ? (WIDTH / 100) * tasteX - PADX
        : (HEIGHT / 100) * (100 - tasteY) - PADY,
    size4:
      (WIDTH / 100) * (100 - tasteX) <= (HEIGHT / 100) * (100 - tasteY)
        ? (WIDTH / 100) * (100 - tasteX)
        : (HEIGHT / 100) * (100 - tasteY) - PADY,
  };
  console.log("TASTE:", taste);
  return (
    <div className="d-flex flex-column gap-5 align-items-center tasting-summary">
      {props.tasting?.aroma_quality_1_id && (
        <div className="d-flex flex-column align-items-center">
          <h1 className="mb-4">AROMA</h1>
          <div className="matrix-container">
            <div className="top-left-label">{aroma?.q1?.name}</div>
            <div className="top-right-label">{aroma?.q2?.name}</div>
            <div className="bottom-left-label">{aroma?.q3?.name}</div>
            <div className="bottom-right-label">{aroma?.q4?.name}</div>
            <div style={{ left: aroma?.x, top: 0 }} className="bar bar-x"></div>
            <div style={{ left: 0, top: aroma?.y }} className="bar bar-y"></div>
            <div
              style={{
                width: `${aroma.size1}px`,
                height: `${aroma.size1}px`,
                borderRadius: `${aroma.size1 / 2}px`,
                backgroundImage: `url(${aroma.q1?.image_url})`,
              }}
              className="circle top-left"
            ></div>
            <div
              style={{
                width: `${aroma.size2}px`,
                height: `${aroma.size2}px`,
                borderRadius: `${aroma.size2 / 2}px`,
                backgroundImage: `url(${aroma.q2?.image_url})`,
              }}
              className="circle top-right"
            ></div>
            <div
              style={{
                width: `${aroma.size3}px`,
                height: `${aroma.size3}px`,
                borderRadius: `${aroma.size3 / 2}px`,
                backgroundImage: `url(${aroma.q3?.image_url})`,
              }}
              className="circle bottom-left"
            ></div>
            <div
              style={{
                width: `${aroma.size4}px`,
                height: `${aroma.size4}px`,
                borderRadius: `${aroma.size4 / 2}px`,
                backgroundImage: `url(${aroma.q4?.image_url})`,
              }}
              className="circle bottom-right"
            ></div>
          </div>
        </div>
      )}
      {props.tasting?.taste_quality_1_id && (
        <div className="d-flex flex-column align-items-center section">
          <h1 className="mb-4">TASTE</h1>
          <div className="matrix-container">
            <div className="top-left-label">{taste?.q1?.name}</div>
            <div className="top-right-label">{taste?.q2?.name}</div>
            <div className="bottom-left-label">{taste?.q3?.name}</div>
            <div className="bottom-right-label">{taste?.q4?.name}</div>
            <div style={{ left: taste?.x, top: 0 }} className="bar bar-x"></div>
            <div style={{ left: 0, top: taste?.y }} className="bar bar-y"></div>
            <div
              style={{
                width: `${taste.size1}px`,
                height: `${taste.size1}px`,
                borderRadius: `${taste.size1 / 2}px`,
                backgroundImage: `url(${taste.q1?.image_url})`,
              }}
              className="circle top-left"
            ></div>
            <div
              style={{
                width: `${taste.size2}px`,
                height: `${taste.size2}px`,
                borderRadius: `${taste.size2 / 2}px`,
                backgroundImage: `url(${taste.q2?.image_url})`,
              }}
              className="circle top-right"
            ></div>
            <div
              style={{
                width: `${taste.size3}px`,
                height: `${taste.size3}px`,
                borderRadius: `${taste.size3 / 2}px`,
                backgroundImage: `url(${taste.q3?.image_url})`,
              }}
              className="circle bottom-left"
            ></div>
            <div
              style={{
                width: `${taste.size4}px`,
                height: `${taste.size4}px`,
                borderRadius: `${taste.size4 / 2}px`,
                backgroundImage: `url(${taste.q4?.image_url})`,
              }}
              className="circle bottom-right"
            ></div>
          </div>
        </div>
      )}
      {props?.tasting?.memory_quality_id && (
        <div className="d-flex flex-column align-items-center section">
          <h1 className="mb-4">MEMORY</h1>
          <div className="matrix-container">
            <div
              className="mx-auto matrix-container__memory"
              style={{
                backgroundImage: `url(${memoryQuality?.image_url})`,
              }}
            ></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TastingSummary;
