import * as React from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { useContext, useState } from "react";
import { AppContextInterface, AppCtx } from "../App";
import { Quality } from "../models/quality";
import { Wine } from "../models/wine";
import StarsRating from "react-star-rate";
import { Button, Modal } from "react-bootstrap";
import { PlusCircleFill } from "react-bootstrap-icons";
import ShareNotes from "../Components/ShareNotes";
import { Analytics } from "../utilities/Analytics";
import redWineDrawing from "../assets/images/red-wine-drawing.png";
import whiteWineDrawing from "../assets/images/white-wine-drawing.png";
import { AppState } from "../models/app-state";

interface WineSelectionPageProps {}

const WineSelectionPage: React.FunctionComponent<WineSelectionPageProps> = (
  props
) => {
  const navigate = useNavigate();
  const { configuration, tastingContent, setAppState } = useContext(
    AppCtx
  ) as AppContextInterface;
  /* const [shareTastingResponse, shareTastingError, loading, axiosFetch] =
    useAxiosFunction(); */
  // const SELECTION_INTERFACE = configuration?.screens?.home_screen_1;
  const SHARE_INTERFACE = configuration?.screens?.share_note;
  const [showShareModal, setShowShareModal] = useState(false);
  const handleSelection = (wine: Wine) => {
    if (wine?.tasting_note) {
      setAppState((prev) => {
        const tasting = wine?.tasting_note
          ? wine.tasting_note
          : { wine_id: 0, accepted_terms: false };
        return {
          ...prev,
          tasting: {
            ...tasting,
            wine_id: wine.id,
          },
        };
      });
      Analytics.push("note_viewed");
      navigate("/tasting");
    } else {
      setAppState((prev: AppState) => ({
        ...prev,
        tasting: {
          ...prev.tasting,
          wine_id: wine.id,
        },
      }));
      Analytics.push("redo");
      navigate("/how-it-works");
    }
  };

  const handleShowShareNotes = () => {
    setShowShareModal(true);
  };
  const handleCloseShareModal = () => {
    setShowShareModal(false);
  };

  const hasTastingNotes = tastingContent.wines.some((w) => w.tasting_note);

  return (
    <motion.div
      initial={{ y: "100vh" }}
      animate={{ y: 0, transition: { duration: 0.15, type: "easeOut" } }}
      className="d-flex flex-column align-items-center text-center gap-2 page-container wine-selection-page"
    >
      <div className="mx-3 mb-3 options-container">
        <h6 className="my-3 group-name">
          {tastingContent?.tasting_menu?.name}
        </h6>
        <div className="wine-options d-flex flex-row flex-wrap gap-3">
          {tastingContent?.wines?.map((wine: Wine, idx: number) => {
            const backgroundImage = wine?.memory_qualities?.find(
              (q: Quality) => q.id === wine?.tasting_note?.memory_quality_id
            )?.image_url;
            const stars = wine?.tasting_note?.stars || -1;
            return (
              <div
                key={idx}
                className={`wine-option d-flex text-center px-2 shadow py-2`}
                onClick={() => {
                  handleSelection(wine);
                }}
              >
                {!wine?.tasting_note && (
                  <div className="note-cta col-2">
                    <PlusCircleFill />
                    <span>Add note</span>
                  </div>
                )}
                {wine?.tasting_note && backgroundImage && (
                  <div
                    className="background-image-cta col-2"
                    style={{
                      backgroundImage: `url(${backgroundImage})`,
                    }}
                  ></div>
                )}
                {wine?.tasting_note && !backgroundImage && (
                  <div className="view-cta col-2">View</div>
                )}
                <div className="title-stars-container col-8">
                  {stars > -1 && <StarsRating value={stars} />}
                  <h6 className="title">{wine?.name}</h6>
                </div>
                <div className="col-2">
                  <div
                    className="wine-drawing"
                    style={{
                      backgroundImage: `url(${
                        wine?.color === "red"
                          ? redWineDrawing
                          : whiteWineDrawing
                      })`,
                    }}
                  ></div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="cta-container col-12 px-3 d-flex gap-4 justify-content-between">
        {hasTastingNotes && (
          <Button className="cta-button" onClick={handleShowShareNotes}>
            Share my notes
          </Button>
        )}
        <Button
          className="flex-grow-1 cta-button"
          onClick={() => {
            Analytics.push("joined");
            window.location.href = configuration?.club || "";
          }}
        >
          Join the club
        </Button>
      </div>
      <Modal
        show={showShareModal}
        fullscreen={true}
        onHide={handleCloseShareModal}
        className="share-note-modal"
      >
        <Modal.Body>
          <ShareNotes
            allNotes={true}
            title={SHARE_INTERFACE?.title || ""}
            subtitle={SHARE_INTERFACE?.body || ""}
            handleClose={handleCloseShareModal}
          />
        </Modal.Body>
      </Modal>
      {/* <Modal
        show={showTastingNote}
        fullscreen={true}
        onHide={handleCloseTastingNote}
        className="tasting-note-modal"
      >
        <Modal.Header closeButton>
          <span>A TASTING NOTE BY {tastingData.customer?.name}</span>
        </Modal.Header>
        <Modal.Body>
          <TastingNotePage
            wine={tastingNoteWine}
            customer={tastingData.customer}
            handleClose={handleCloseTastingNote}
          />
        </Modal.Body>
      </Modal> */}
    </motion.div>
  );
};

export default WineSelectionPage;
