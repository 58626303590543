import * as React from "react";
import { Button, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { useContext, useState } from "react";
import { AppContextInterface, AppCtx } from "../App";
import { Analytics } from "../utilities/Analytics";

interface CreateTastingRoomPageProps {}

const CreateTastingRoomPage: React.FunctionComponent<
  CreateTastingRoomPageProps
> = (props) => {
  const navigate = useNavigate();
  const { configuration, appState, setAppState } = useContext(
    AppCtx
  ) as AppContextInterface;
  const [name, setName] = useState(appState?.customer?.name || "");
  const [attested, setAttested] = useState(
    appState?.customer?.accepted_terms || false
  );
  const CREATE_INTERFACE = configuration?.screens?.onboarding;
  return (
    <motion.div
      initial={{ y: "100vh" }}
      animate={{ y: 0, transition: { duration: 0.15, type: "easeOut" } }}
      className="d-flex flex-column gap-2 align-items-center text-center page-container px-3 create-room-page"
    >
      <span className="page-title mt-3 mb-4">
        Let's create your Tasting Room
      </span>
      <div className="mb-2 room-form">
        <Form.Control
          className="name-field"
          name="name"
          type="text"
          value={name}
          placeholder={CREATE_INTERFACE?.body}
          onChange={(e) => {
            setAppState((prev) => ({
              ...prev,
              customer: { ...prev.customer, name: e.target.value },
            }));
            setName(e.target.value);
          }}
        ></Form.Control>
      </div>
      <div className="d-flex flex-row gap-3 justify-content-center attestation">
        <Form.Check
          className="d-flex flex-column justify-content-center attest-checkbox"
          checked={attested}
          type="checkbox"
          onChange={(e) => {
            setAppState((prev) => ({
              ...prev,
              customer: {
                ...prev.customer,
                accepted_terms: !!e.target.checked,
              },
            }));
            setAttested(!!e.target.checked);
          }}
        />
        <label className="small">
          By checking this box, you confirm you are at least 21 years old, and
          have read and agree to our &nbsp;
          <a target="_blank" href={configuration?.terms || ""}>
            terms &amp; conditions
          </a>
          &nbsp;and&nbsp;
          <a target="_blank" href={configuration?.privacy_policy}>
            privacy policy
          </a>
        </label>
      </div>

      <Button
        className="mx-2 mb-auto mt-2 cta-button"
        variant="primary"
        disabled={!attested || !name.length}
        onClick={() => {
          const customer = appState?.customer
            ? {
                ...appState?.customer,
                name,
                accepted_terms: attested,
                sawHowTo: false,
              }
            : { name, email: "", sawHowTo: false };
          setAppState({ ...appState, customer });
          Analytics.push("onboarded");
          navigate("/select");
        }}
      >
        NEXT
      </Button>
    </motion.div>
  );
};

export default CreateTastingRoomPage;
