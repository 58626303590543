import * as React from "react";
import { useContext } from "react";
import { NavLink } from "react-router-dom";
import { AppCtx, AppContextInterface } from "../App";
import WHSmallLogo from "./WHSmallLogo";

export interface RoomHeaderProps {}

export function RoomHeader(props: RoomHeaderProps) {
  const {
    configuration,
    appState,
    tastingContent: tastingData,
  } = useContext(AppCtx) as AppContextInterface;
  const selectedWine = tastingData?.wines?.find(
    (wine: any) => wine?.id == appState?.tasting?.wine_id
  );
  const name = selectedWine
    ? selectedWine?.name
    : `${appState?.customer?.name || "Someone"}'s Tasting Room`;
  return (
    <header className="d-flex flex-column align-items-center text-center room-header">
      {!selectedWine && <h5 className="mt-3">{name}</h5>}
      {selectedWine && (
        <div className="pb-2 container">
          <h5 className="py-0">{selectedWine?.name}</h5>
          <div className="d-flex flex-row justify-content-between py-1">
            <NavLink
              to="/aroma"
              className={({ isActive }) =>
                `px-2 py-1 nav-button ${isActive ? "active" : "inactive"}`
              }
            >
              Aroma
            </NavLink>
            <NavLink
              to="/taste"
              className={({ isActive }) =>
                `px-2 py-1 nav-button ${isActive ? "active" : "inactive"}`
              }
            >
              Taste
            </NavLink>
            <NavLink
              to="/memory"
              className={({ isActive }) =>
                `px-2 py-1 nav-button ${isActive ? "active" : "inactive"}`
              }
            >
              Memory
            </NavLink>
            <NavLink
              to="/note"
              className={({ isActive }) =>
                `px-2 py-1 nav-button ${isActive ? "active" : "inactive"}`
              }
            >
              Notes
            </NavLink>
          </div>
        </div>
      )}
    </header>
  );
}
