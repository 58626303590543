import * as React from "react";
import { Button } from "react-bootstrap";
import { Mailbox } from "react-bootstrap-icons";

interface MessageProps {
  title: string;
  message: string;
  buttonLabel: string;
  buttonAction: Function;
}

const Message: React.FunctionComponent<MessageProps> = (props) => {
  return (
    <div className="d-flex flex-column gap-5 text-center align-items-center message-content">
      <span className="title">{props?.title}</span>
      <Mailbox className="icon" />
      <span className="mx-2 message">{props?.message}</span>
      <Button
        className="cta-button"
        onClick={() => {
          props.buttonAction();
        }}
      >
        {props?.buttonLabel}
      </Button>
    </div>
  );
};

export default Message;
