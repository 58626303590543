import * as React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

interface NavigationButtonsProps {
  nextLabel?: string;
  nextHandler: Function;
  skipTo?: string;
  skipHandler?: Function;
}

const NavigationButtons: React.FunctionComponent<NavigationButtonsProps> = (
  props
) => {
  return (
    <div className="d-flex flex-row align-items-center gap-3 navigation-buttons">
      <div
        className="link"
        onClick={() => {
          window.history.back();
        }}
      >
        Back
      </div>
      <Button
        className="cta-button"
        variant="primary"
        onClick={() => {
          props.nextHandler();
        }}
      >
        {props?.nextLabel ? props.nextLabel : "Next"}
      </Button>
      {props.skipTo && props.skipHandler && (
        <Link
          className="link"
          to={props.skipTo}
          onClick={() => {
            props.skipHandler && props.skipHandler();
          }}
        >
          Skip
        </Link>
      )}
      {!props.skipTo && props.skipHandler && (
        <span
          className="mx-0 px-0 link"
          onClick={() => {
            props.skipHandler && props.skipHandler();
          }}
        >
          Skip
        </span>
      )}
    </div>
  );
};

export default NavigationButtons;
