import { useState, useEffect } from "react";
import { ConfigObject } from "./useAxios";

const useAxiosFunction = () => {
  const [response, setResponse] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [controller, setController] = useState<AbortController>();

  const axiosFetch = async (configObj: ConfigObject) => {
    const { axiosInstance, method, url, body, requestConfig = {} } = configObj;

    try {
      setLoading(true);
      const ctrl = new AbortController();
      setController(ctrl);
      const res = body
        ? await axiosInstance[method.toLowerCase()](url, body, {
            ...requestConfig,
            signal: ctrl.signal,
          })
        : await axiosInstance[method.toLowerCase()](url, {
            ...requestConfig,
            signal: ctrl.signal,
          });
      console.log(res);
      setResponse(res.data);
    } catch (err) {
      let message;
      if (err instanceof Error) message = err.message;
      else message = String(err);
      setError(message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // useEffect cleanup function
    return () => controller && controller.abort();
  }, [controller]);

  return [response, error, loading, axiosFetch] as const;
};

export default useAxiosFunction;
