import * as React from "react";
import { useState } from "react";
import { Quality } from "../models/quality";

export interface MemoryChooserProps {
  availableQualities: Quality[];
  updateSelectedQuality: (value: number) => void;
  previousSelectionId?: number;
}

export function MemoryChooser(props: MemoryChooserProps) {
  const [imageIndex, setImageIndex] = useState(
    props.previousSelectionId
      ? props.availableQualities.findIndex(
          (q) => q.id === props.previousSelectionId
        )
      : 0
  );
  /* const importAll = (r: __WebpackModuleApi.RequireContext) => {
    console.log(r.keys());
    let images: any = {};
    r.keys().map((item, idx) => {
      images[idx] = r(item);
    });
    return images;
  };
  const memoryImages = importAll(
    require.context("../assets/images/memories", false)
  ); */
  const memoryImages = props.availableQualities.map((qual) => qual.image_url);

  const handleTap = () => {
    console.log(imageIndex, Object.keys(memoryImages).length);
    let newIndex = 0;
    if (imageIndex < Object.keys(memoryImages).length - 1) {
      newIndex = imageIndex + 1;
    }
    setImageIndex(newIndex);
    props.updateSelectedQuality(props.availableQualities[newIndex].id);
  };
  return (
    <div className="d-flex flex-column text-center mx-3 gap-3 memory-chooser">
      <span className="small mt-3">
        <span className="fw-bold">Tap image</span> to change
      </span>
      <div
        className="mx-auto mb-3 tappable-image"
        style={{
          backgroundImage: `url(${memoryImages[imageIndex]})`,
        }}
        onClick={handleTap}
      ></div>
    </div>
  );
}
