import * as React from "react";
import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { RoomHeader } from "../Components/RoomHeader";
import { motion } from "framer-motion";
import PerceptionChooser from "../Components/PerceptionChooser";
import { useContext, useState } from "react";
import { AppContextInterface, AppCtx } from "../App";
import { Quality } from "../models/quality";
import { Analytics } from "../utilities/Analytics";

import NavigationButtons from "../Components/NavigationButtons";
import { QualityUtils } from "../utilities/QualityUtils";

export interface AromaSelectionPageProps {}

export function AromaSelectionPage(props: AromaSelectionPageProps) {
  const navigate = useNavigate();
  const { configuration, appState, setAppState, tastingContent } = useContext(
    AppCtx
  ) as AppContextInterface;
  const AROMA_INTERFACE = configuration?.screens?.aroma_interface;
  const selectedWine = tastingContent?.wines?.find(
    (wine: any) => wine?.id == appState?.tasting?.wine_id
  );
  let availableQualities = selectedWine?.aroma_qualities
    ? [...selectedWine?.aroma_qualities]
    : [];
  // console.log("AvailableQualities: ", availableQualities);
  let aroma = {
    aroma_quality_1_id: appState?.tasting?.aroma_quality_1_id || 0,
    aroma_quality_2_id: appState?.tasting?.aroma_quality_2_id || 0,
    aroma_quality_3_id: appState?.tasting?.aroma_quality_3_id || 0,
    aroma_quality_4_id: appState?.tasting?.aroma_quality_4_id || 0,
    aroma_x: appState?.tasting?.aroma_x || 0,
    aroma_y: appState?.tasting?.aroma_y || 0,
  };
  // console.log("Aroma values:", aroma);
  // console.log("selected wine: ", selectedWine);
  // console.log("tasting values:", appState?.tasting);

  return (
    <motion.div
      initial={{ y: "100vh" }}
      animate={{
        y: 0,
        transition: { duration: 0.15, type: "easeOut", when: "beforeChildren" },
      }}
      className="d-flex flex-column text-center gap-2 align-items-center page-container aroma-selection-page"
    >
      <>
        <h6 className="title">{AROMA_INTERFACE?.body}</h6>
        <h3 className="subtitle">{AROMA_INTERFACE?.title}</h3>
        <PerceptionChooser
          previousValues={{
            qualities: [
              QualityUtils.qualityById(
                aroma?.aroma_quality_1_id,
                availableQualities
              ) || availableQualities[0],
              QualityUtils.qualityById(
                aroma?.aroma_quality_2_id,
                availableQualities
              ) || availableQualities[1],

              QualityUtils.qualityById(
                aroma?.aroma_quality_3_id,
                availableQualities
              ) || availableQualities[2],

              QualityUtils.qualityById(
                aroma?.aroma_quality_4_id,
                availableQualities
              ) || availableQualities[3],
            ],
            x: aroma.aroma_x,
            y: aroma.aroma_y,
          }}
          availableQualities={availableQualities}
          updateSelectedQualities={(q: Quality[], x: number, y: number) => {
            // console.log("Updated Qualities:", q);

            if (aroma.aroma_x != x || aroma.aroma_y != y) {
              // Drag update
              console.log("Drag update");
              Analytics.push("aroma_drag", {
                wine_name: selectedWine?.name || "",
                wines_completed: Analytics.tastingsCompleted(
                  tastingContent.wines
                ),
              });
            }
            if (
              aroma.aroma_quality_1_id !== q[0].id ||
              aroma.aroma_quality_2_id !== q[1].id ||
              aroma.aroma_quality_3_id !== q[2].id ||
              aroma.aroma_quality_4_id !== q[3].id
            ) {
              // Tap update
              console.log("Tap update");
              Analytics.push("aroma_tap", {
                wine_name: selectedWine?.name || "",
                wines_completed: Analytics.tastingsCompleted(
                  tastingContent.wines
                ),
              });
            }
            if (q.length == 4) {
              aroma = {
                aroma_quality_1_id: q[0]?.id,
                aroma_quality_2_id: q[1]?.id,
                aroma_quality_3_id: q[2]?.id,
                aroma_quality_4_id: q[3]?.id,
                aroma_x: x,
                aroma_y: y,
              };
              setAppState((prev) => {
                return { ...prev, tasting: { ...prev.tasting, ...aroma } };
              });
            }
          }}
        />
        <NavigationButtons
          nextLabel="NEXT: TASTE"
          nextHandler={() => {
            setAppState((prev) => {
              return { ...prev, tasting: { ...prev.tasting, ...aroma } };
            });
            Analytics.push("aroma_completed", {
              wine_name: selectedWine?.name || "",
            });
            navigate("/taste");
          }}
          skipTo="/taste"
          skipHandler={() => {
            Analytics.push("aroma_skipped", {
              wine_name: selectedWine?.name || "",
            });
          }}
        />
      </>
    </motion.div>
  );
}
