import { motion } from "framer-motion";
import * as React from "react";
import { useContext } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { AppContextInterface, AppCtx } from "../App";
import helpimage from "../assets/images/drag-how-to.png";
import NavigationButtons from "../Components/NavigationButtons";
import { Analytics } from "../utilities/Analytics";

interface HowItWorksProps {}

const HowItWorks: React.FunctionComponent<HowItWorksProps> = (props) => {
  const { configuration, appState, setAppState, tastingContent } = useContext(
    AppCtx
  ) as AppContextInterface;
  const navigate = useNavigate();
  const selectedWine = tastingContent?.wines?.find(
    (wine: any) => wine?.id == appState?.tasting?.wine_id
  );
  return (
    <motion.div
      initial={{ y: "100vh" }}
      animate={{
        y: 0,
        transition: { duration: 0.15, type: "easeOut", when: "beforeChildren" },
      }}
      className="d-flex flex-column text-center gap-2 align-items-center page-container aroma-selection-page"
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="d-flex flex-column gap-3 align-items-center instructions"
      >
        <h2 className="title">Here's how it works</h2>
        <img className="image" src={helpimage}></img>
        <NavigationButtons
          nextLabel="LET'S GO"
          nextHandler={() => {
            Analytics.push("how_it_works_completed", {
              wine_name: selectedWine?.name || "",
            });
            setAppState((prev) => ({
              ...prev,
              customer: { ...prev.customer, sawHowTo: true },
            }));
            navigate("/aroma");
          }}
        />
      </motion.div>
    </motion.div>
  );
};

export default HowItWorks;
