import * as React from "react";
import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { RoomHeader } from "../Components/RoomHeader";
import { motion } from "framer-motion";
import PerceptionChooser from "../Components/PerceptionChooser";
import { useContext } from "react";
import { AppContextInterface, AppCtx } from "../App";
import { Quality } from "../models/quality";
import { Analytics } from "../utilities/Analytics";
import NavigationButtons from "../Components/NavigationButtons";
import { QualityUtils } from "../utilities/QualityUtils";

export interface TasteSelectionPageProps {}

export function TasteSelectionPage(props: TasteSelectionPageProps) {
  const navigate = useNavigate();
  const { configuration, appState, setAppState, tastingContent } = useContext(
    AppCtx
  ) as AppContextInterface;
  const TASTE_INTERFACE = configuration?.screens?.taste_interface;
  const selectedWine = tastingContent?.wines?.find(
    (wine: any) => wine?.id == appState?.tasting?.wine_id
  );

  let taste = {
    taste_quality_1_id: appState?.tasting?.taste_quality_1_id || 0,
    taste_quality_2_id: appState?.tasting?.taste_quality_2_id || 0,
    taste_quality_3_id: appState?.tasting?.taste_quality_3_id || 0,
    taste_quality_4_id: appState?.tasting?.taste_quality_4_id || 0,
    taste_x: appState?.tasting?.taste_x || 0,
    taste_y: appState?.tasting?.taste_y || 0,
  };

  let availableQualities = selectedWine?.taste_qualities
    ? [...selectedWine.taste_qualities]
    : [];
  return (
    <motion.div
      initial={{ y: "100vh" }}
      animate={{ y: 0, transition: { duration: 0.15, type: "easeOut" } }}
      className="d-flex flex-column text-center gap-3 align-items-center page-container taste-selection-page"
    >
      <h6 className="title">{TASTE_INTERFACE?.body}</h6>
      <h3 className="subtitle">{TASTE_INTERFACE?.title}</h3>
      <PerceptionChooser
        previousValues={{
          qualities: [
            QualityUtils.qualityById(
              taste?.taste_quality_1_id,
              availableQualities
            ) || availableQualities[0],
            QualityUtils.qualityById(
              taste?.taste_quality_2_id,
              availableQualities
            ) || availableQualities[1],

            QualityUtils.qualityById(
              taste?.taste_quality_3_id,
              availableQualities
            ) || availableQualities[2],

            QualityUtils.qualityById(
              taste?.taste_quality_4_id,
              availableQualities
            ) || availableQualities[3],
          ],
          x: taste.taste_x,
          y: taste.taste_y,
        }}
        availableQualities={availableQualities}
        updateSelectedQualities={(q: Quality[], x: number, y: number) => {
          if (taste.taste_x != x || taste.taste_y != y) {
            // Drag update
            Analytics.push("taste_drag", {
              wine_name: selectedWine?.name || "",
              wines_completed: Analytics.tastingsCompleted(
                tastingContent.wines
              ),
            });
          }
          if (
            taste.taste_quality_1_id != q[0].id ||
            taste.taste_quality_2_id != q[1].id ||
            taste.taste_quality_3_id != q[2].id ||
            taste.taste_quality_4_id != q[3].id
          ) {
            // Tap update
            Analytics.push("aroma_tap", {
              wine_name: selectedWine?.name || "",
              wines_completed: Analytics.tastingsCompleted(
                tastingContent.wines
              ),
            });
          }
          taste = {
            taste_quality_1_id: q[0].id,
            taste_quality_2_id: q[1].id,
            taste_quality_3_id: q[2].id,
            taste_quality_4_id: q[3].id,
            taste_x: x,
            taste_y: y,
          };
          setAppState((prev) => {
            return { ...prev, tasting: { ...prev.tasting, ...taste } };
          });
        }}
      />
      <NavigationButtons
        nextLabel="NEXT: MEMORY"
        nextHandler={() => {
          setAppState((prev) => {
            return { ...prev, tasting: { ...prev.tasting, ...taste } };
          });
          Analytics.push("taste_completed", {
            wine_name: selectedWine?.name || "",
          });
          navigate("/memory");
        }}
        skipTo="/memory"
        skipHandler={() => {
          Analytics.push("taste_skipped", {
            wine_name: selectedWine?.name || "",
          });
        }}
      />
    </motion.div>
  );
}
