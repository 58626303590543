import * as React from "react";
import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { MemoryChooser } from "../Components/MemoryChooser";
import { RoomHeader } from "../Components/RoomHeader";
import { motion } from "framer-motion";
import { useContext } from "react";
import { AppContextInterface, AppCtx } from "../App";
import { Quality } from "../models/quality";
import { Analytics } from "../utilities/Analytics";
import NavigationButtons from "../Components/NavigationButtons";

export interface MemorySelectionPageProps {}

export function MemorySelectionPage(props: MemorySelectionPageProps) {
  const navigate = useNavigate();
  const { configuration, appState, setAppState, tastingContent } = useContext(
    AppCtx
  ) as AppContextInterface;
  const MEMORY_INTERFACE = configuration?.screens?.memory_interface;
  const selectedWine = tastingContent?.wines?.find(
    (wine: any) => wine?.id == appState?.tasting?.wine_id
  );
  let availableQualities = selectedWine?.memory_qualities
    ? [...selectedWine?.memory_qualities]
    : [];

  return (
    <motion.div
      initial={{ y: "100vh" }}
      animate={{ y: 0, transition: { duration: 0.15, type: "easeOut" } }}
      className="d-flex flex-column text-center gap-3 align-items-center page-container memory-selection-page"
    >
      <h6 className="title">{MEMORY_INTERFACE?.body}</h6>
      <h4 className="subtitle">{MEMORY_INTERFACE?.title}</h4>
      <MemoryChooser
        previousSelectionId={appState?.tasting?.memory_quality_id}
        availableQualities={availableQualities}
        updateSelectedQuality={(id: number) => {
          setAppState((prev) => {
            return {
              ...prev,
              tasting: { ...prev.tasting, memory_quality_id: id },
            };
          });
          Analytics.push("memory_tap", {
            wine_name: selectedWine?.name || "",
          });
        }}
      />
      <NavigationButtons
        nextLabel="NEXT: ADD NOTE"
        nextHandler={() => {
          Analytics.push("memory_completed", {
            wine_name: selectedWine?.name || "",
          });
          navigate("/note");
        }}
        skipTo="/note"
        skipHandler={() => {
          Analytics.push("memory_skipped", {
            wine_name: selectedWine?.name || "",
          });
        }}
      />
    </motion.div>
  );
}
