import React, { ElementRef, useEffect, useReducer, useState } from "react";
import { motion, useMotionValue, useTransform } from "framer-motion";
import { useRef } from "react";
import _ from "lodash";
import { Quality } from "../models/quality";
import { ArrowsMove } from "react-bootstrap-icons";

interface PerceptionChooserProps {
  availableQualities: any[];
  updateSelectedQualities: (value: Quality[], x: number, y: number) => void;
  previousValues?: { qualities: Quality[]; x: number; y: number };
}

const reducer = (state: any, action: { type: string; payload: number }) => {
  switch (action?.type) {
    case "SWAP": {
      let avail = [
        ...state.availableQualities,
        state.selectedQualities[action?.payload],
      ];
      let newQ = avail.shift();
      let newSelected = Object.assign([], state.selectedQualities, {
        [action?.payload]: newQ,
      });

      return {
        availableQualities: avail,
        selectedQualities: newSelected,
      };
    }
    default:
      throw new Error();
  }
};

const PerceptionChooser: React.FunctionComponent<PerceptionChooserProps> = (
  props
) => {
  const WIDTH = 300;
  const HEIGHT = 390;

  const previousQualityIds = props?.previousValues?.qualities?.map((q) => q.id);
  const initialState = {
    availableQualities: props?.previousValues?.qualities
      ? props.availableQualities.filter(
          (q) => !previousQualityIds?.includes(q.id)
        )
      : props.availableQualities.slice(4),
    selectedQualities:
      props?.previousValues?.qualities || props.availableQualities.slice(0, 4),
  };
  // console.log("Initial state: ", initialState);

  const [state, dispatch] = useReducer(reducer, initialState);

  const containerRef = useRef<HTMLDivElement>(null);

  const x = useMotionValue(
    props?.previousValues?.x
      ? (WIDTH / 100) * props.previousValues.x
      : WIDTH / 2
  );
  const y = useMotionValue(
    props?.previousValues?.y
      ? 0 - (HEIGHT / 100) * (100 - props.previousValues.y)
      : -(HEIGHT / 2)
  );
  let cachedX = 50;
  let cachedY = 50;

  const barX = useTransform(x, (value) => {
    /* console.log(
      "PERCEPTION: bar XY:",
      (100 * Math.abs(x.get())) / WIDTH,
      100 - (100 * Math.abs(y.get())) / HEIGHT
    ); */
    cachedX = Math.ceil((100 * Math.abs(x.get())) / WIDTH);
    cachedY = Math.ceil(100 - (100 * Math.abs(y.get())) / HEIGHT);
    // props.updateSelectedQualities(state.selectedQualities, cachedX, cachedY);
    return value + 25;
  });
  const barY = useTransform(y, (value) => {
    /* console.log(
      "PERCEPTION: bar XY:",
      (100 * Math.abs(x.get())) / WIDTH,
      100 - (100 * Math.abs(y.get())) / HEIGHT
    ); */
    cachedX = Math.ceil((100 * Math.abs(x.get())) / WIDTH);
    cachedY = Math.ceil(100 - (100 * Math.abs(y.get())) / HEIGHT);
    // props.updateSelectedQualities(state.selectedQualities, cachedX, cachedY);
    return value + 25;
  });

  const circleBorderTL = useTransform(x, (value) => {
    return value / 2;
  });

  const circleBorderTR = useTransform(x, (value) => {
    return 175 - value / 2;
  });

  const sizeTL = useTransform([x, y], ([latestX, latestY]: number[]) => {
    // console.log("latest:", Math.abs(latestX), 275 - Math.abs(latestY));
    /* console.log(
      "container width:",
      containerRef?.current?.clientWidth,
      containerRef.current?.clientHeight
    ); */
    const x = Math.abs(latestX) - 5;
    const y = 330 - Math.abs(latestY);
    // console.log("Size:", x < y ? x : y);
    return x < y ? x : y;
  });

  const sizeTR = useTransform([x, y], ([latestX, latestY]: number[]) => {
    // console.log("latest:", Math.abs(latestX), 275 - Math.abs(latestY));
    const x = 295 - Math.abs(latestX);
    const y = 330 - Math.abs(latestY);
    // console.log("Size:", x < y ? x : y);
    return x < y ? x : y;
  });

  const sizeBR = useTransform([x, y], ([latestX, latestY]: number[]) => {
    // console.log("latest:", Math.abs(latestX), 275 - Math.abs(latestY));
    const x = 295 - Math.abs(latestX);
    const y = Math.abs(latestY) - 55;
    // console.log("Size:", x < y ? x : y);
    return x < y ? x : y;
  });

  const sizeBL = useTransform([x, y], ([latestX, latestY]: number[]) => {
    // console.log("latest:", Math.abs(latestX), 275 - Math.abs(latestY));
    const x = Math.abs(latestX) - 5;
    const y = Math.abs(latestY) - 55;
    // console.log("Size:", x < y ? x : y);
    return x < y ? x : y;
  });

  const swapImage = (idx: number) => {
    console.log("swapping image:", idx, state.selectedQualities[idx]);
    dispatch({ type: "SWAP", payload: idx });
    props.updateSelectedQualities(state.selectedQualities, cachedX, cachedY);
  };

  useEffect(() => {
    props.updateSelectedQualities(state.selectedQualities, cachedX, cachedY);
  }, [state]);

  return (
    <div className="d-flex flex-column perception-chooser">
      <div ref={containerRef} className="mx-auto chooser">
        <div className="top-left-label">{state.selectedQualities[0]?.name}</div>
        <div className="top-right-label">
          {state.selectedQualities[1]?.name}
        </div>
        <div className="bottom-left-label">
          {state.selectedQualities[2]?.name}
        </div>
        <div className="bottom-right-label">
          {state.selectedQualities[3]?.name}
        </div>
        <motion.div style={{ x: barX }} className="bar bar-x"></motion.div>
        <motion.div style={{ y: barY }} className="bar bar-y"></motion.div>
        <motion.div
          style={{
            width: sizeTL,
            height: sizeTL,
            borderRadius: circleBorderTL,
            backgroundImage: `url(${state.selectedQualities[0]?.image_url})`,
          }}
          className="circle top-left"
          onClick={() => {
            swapImage(0);
          }}
        ></motion.div>
        <motion.div
          style={{
            width: sizeTR,
            height: sizeTR,
            borderRadius: circleBorderTR,
            backgroundImage: `url(${state.selectedQualities[1]?.image_url})`,
          }}
          className="circle top-right"
          onClick={() => {
            swapImage(1);
          }}
        ></motion.div>
        <motion.div
          style={{
            width: sizeBL,
            height: sizeBL,
            borderRadius: circleBorderTL,
            backgroundImage: `url(${state.selectedQualities[2]?.image_url})`,
          }}
          className="circle bottom-left"
          onClick={() => {
            swapImage(2);
          }}
        ></motion.div>
        <motion.div
          style={{
            width: sizeBR,
            height: sizeBR,
            borderRadius: circleBorderTR,
            backgroundImage: `url(${state.selectedQualities[3]?.image_url})`,
          }}
          className="circle bottom-right"
          onClick={() => {
            swapImage(3);
          }}
        ></motion.div>
        <motion.div
          drag
          dragMomentum={false}
          dragSnapToOrigin={false}
          style={{ x, y }}
          dragConstraints={{ left: 80, right: 215, top: -260, bottom: -130 }}
          className="d-flex flex-column align-items-center justify-content-center handle"
          onDragEnd={() => {
            console.log("Drag End");
            props.updateSelectedQualities(
              state.selectedQualities,
              cachedX,
              cachedY
            );
            // console.log("PERCEPTION: XY effect:", cachedX, cachedY);
          }}
        >
          <ArrowsMove />
        </motion.div>
      </div>
    </div>
  );
};

export default PerceptionChooser;
