import * as React from "react";
import { useState } from "react";
import { Form } from "react-bootstrap";
import StarsRating from "react-star-rate";
import { Analytics } from "../utilities/Analytics";

export interface NoteEditorProps {
  note: string;
  rating: number;
  updateNote: (note: string, rating: number) => void;
}

export function NoteEditor(props: NoteEditorProps) {
  const [rating, setRating] = useState(props.rating || 0);
  const [note, setNote] = useState(props.note || "");
  return (
    <div className="d-flex flex-column text-center align-items-center pb-3 gap-2 mx-3 note-editor">
      <span className="small mt-3">My rating</span>
      <StarsRating
        value={rating}
        onChange={(value) => {
          setRating(value as number);
          props.updateNote(note, value as number);
          Analytics.push("rated");
        }}
      />
      <Form.Control
        className="mx-3 mt-2 notes-field"
        as="textarea"
        rows={5}
        value={note}
        onChange={(e) => {
          setNote(e.target.value);
          props.updateNote(e.target.value, rating);
          Analytics.push("commented");
        }}
      ></Form.Control>
    </div>
  );
}
