import * as React from "react";
import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import WHLogo from "../Components/WHLogo";
import { motion } from "framer-motion";
import { useContext } from "react";
import { AppContextInterface, AppCtx } from "../App";
import { Analytics } from "../utilities/Analytics";

interface LandingPageProps {}

const LandingPage: React.FunctionComponent<LandingPageProps> = (props) => {
  const navigate = useNavigate();
  const {
    configuration,
    appState,
    setAppState,
    tastingContent: tastingData,
  } = useContext(AppCtx) as AppContextInterface;
  const LANDING_INTERFACE = configuration?.screens?.start;
  return (
    <motion.div
      initial={{ y: "100vh" }}
      animate={{ y: 0, transition: { duration: 0.15, type: "easeOut" } }}
      className="d-flex flex-column gap-2 text-center pt-2 px-2 align-items-center page-container landing-page"
    >
      <div className="mb-2 mt-1 logo-container">
        <WHLogo />
      </div>
      <div
        className="mx-4 mt-1 center-image"
        style={{
          backgroundImage: `url(${LANDING_INTERFACE?.image_url})`,
        }}
      ></div>
      <Button
        variant="primary"
        className="cta-button"
        onClick={() => {
          Analytics.push("tasting_started");
          if (appState?.customer?.name) {
            navigate("/select");
          } else {
            navigate("/create");
          }
        }}
      >
        {appState?.customer?.name
          ? `HI ${appState?.customer?.name}! TAP TO CONTINUE`
          : LANDING_INTERFACE?.title}
      </Button>
      <div
        className="mt-auto mb-auto content"
        dangerouslySetInnerHTML={{
          __html: LANDING_INTERFACE?.body as string,
        }}
      ></div>
    </motion.div>
  );
};

export default LandingPage;
