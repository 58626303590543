import React from "react";

const WHLogo: React.FunctionComponent = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      clipRule="evenodd"
      viewBox="0 0 174 30"
    >
      <path
        fill="#4A4444"
        fillRule="nonzero"
        d="M134.773 16.359V.888h-2.656v6.569h-10.285V.888h-2.656v15.471h2.656V9.742h10.285v6.57h2.656v.047zM17.678.888h-2.704l.048 10.378v1.095L9.131 8.314 3.24 12.361V8.409L3.192.84H.584v16.138l8.547-5.808 8.547 5.713M88.273 16.359h2.704l-.049-10.378V4.886l5.891 4.047 5.891-4.047v3.952l.049 7.569h2.655V.221l-8.546 5.808L88.321.316"
        transform="translate(-.584 -.221)"
      ></path>
      <path
        fill="#4A4444"
        fillRule="nonzero"
        d="M24.003 0.888H26.611V16.36H24.003z"
        transform="translate(-.584 -.221)"
      ></path>
      <path
        fill="#4A4444"
        fillRule="nonzero"
        d="M140.953 0.888H143.561V16.36H140.953z"
        transform="translate(-.584 -.221)"
      ></path>
      <path
        fill="#4A4444"
        fillRule="nonzero"
        d="M160.364 14.312h-7.967V.888h-2.656v15.471h10.623v-2.047zM174.416 14.312H166.4V.888h-2.607v15.471h10.623v-2.047zM43.27 14.312h-7.968V.888h-2.655v15.471H43.27v-2.047zM57.321 14.312h-7.967V.888h-2.656v15.471h10.623v-2.047z"
        transform="translate(-.584 -.221)"
      ></path>
      <path
        fill="#4A4444"
        fillRule="nonzero"
        d="M61.474 0.888H64.081V16.36H61.474z"
        transform="translate(-.584 -.221)"
      ></path>
      <path
        fill="url(#_Linear1)"
        fillRule="nonzero"
        d="M42.062 29.165v-4.951h4.684v.714h-3.863v1.238h2.559v.714h-2.559v1.571h4.105v.714h-4.926z"
        transform="translate(-.584 -.221)"
      ></path>
      <path
        fill="url(#_Linear2)"
        fillRule="nonzero"
        d="M49.885 28.499l.386-.619c.338.238.724.381 1.111.523.386.143.772.191 1.159.191.434 0 .82-.095 1.062-.286.29-.19.386-.428.386-.666 0-.238-.145-.476-.386-.619-.242-.143-.628-.191-1.159-.238-.917 0-1.545-.095-1.883-.333-.338-.191-.483-.524-.483-.953 0-.428.193-.761.579-.999.387-.238.87-.381 1.497-.381.435 0 .821.048 1.256.19.386.096.772.286 1.159.524l-.387.619c-.289-.191-.627-.381-1.014-.476-.338-.095-.676-.191-1.014-.191-.386 0-.676.048-.917.191-.242.143-.338.285-.338.523 0 .239.096.381.338.477.241.095.628.142 1.207.142.773 0 1.4.143 1.787.381.386.286.627.667.627 1.19 0 .476-.193.857-.627 1.143-.387.285-.918.476-1.594.476-.483 0-.966-.048-1.448-.191-.483-.19-.918-.38-1.304-.618z"
        transform="translate(-.584 -.221)"
      ></path>
      <path
        fill="url(#_Linear3)"
        fillRule="nonzero"
        d="M59.687 29.117v-4.236h-2.076v-.667h4.973v.667H60.46v4.236h-.773z"
        transform="translate(-.584 -.221)"
      ></path>
      <path
        fill="url(#_Linear4)"
        fillRule="nonzero"
        d="M64.274 29.117l2.511-4.903h.483l2.559 4.903h-.821l-.627-1.237h-2.608l-.628 1.237h-.869zm1.787-1.904h1.931l-.965-1.856-.966 1.856z"
        transform="translate(-.584 -.221)"
      ></path>
      <path
        fill="url(#_Linear5)"
        fillRule="nonzero"
        d="M73.642 29.117v-4.236h-2.076v-.667h4.973v.667h-2.125v4.236h-.772z"
        transform="translate(-.584 -.221)"
      ></path>
      <path
        fill="url(#_Linear6)"
        fillRule="nonzero"
        d="M79.098 29.165v-4.951h4.684v.714h-3.863v1.238h2.559v.714h-2.559v1.571h4.104v.714h-4.925z"
        transform="translate(-.584 -.221)"
      ></path>
      <path
        fill="url(#_Linear7)"
        fillRule="nonzero"
        d="M91.894 29.117l-1.69-4.903h.773l1.207 3.571 1.255-3.571h.628l1.255 3.571 1.208-3.571h.772l-1.69 4.903h-.579l-1.304-3.76-1.304 3.76h-.531z"
        transform="translate(-.584 -.221)"
      ></path>
      <path
        fill="url(#_Linear8)"
        fillRule="nonzero"
        d="M100.586 24.214H101.358V29.116999999999997H100.586z"
        transform="translate(-.584 -.221)"
      ></path>
      <path
        fill="url(#_Linear9)"
        fillRule="nonzero"
        d="M105.125 29.117v-4.903h.386l3.139 3.428v-3.428h.772v4.903h-.386l-3.139-3.427v3.427h-.772z"
        transform="translate(-.584 -.221)"
      ></path>
      <path
        fill="url(#_Linear10)"
        fillRule="nonzero"
        d="M112.995 29.165v-4.951h4.684v.714h-3.863v1.238h2.559v.714h-2.559v1.571h4.105v.714h-4.926z"
        transform="translate(-.584 -.221)"
      ></path>
      <path
        fill="url(#_Linear11)"
        fillRule="nonzero"
        d="M121.59 26.975v2.142h-.772v-4.903h3.428c.531 0 .917.143 1.207.381.29.238.435.571.435 1 0 .333-.097.666-.338.904-.242.238-.531.429-.869.476l1.4 2.142h-.918l-1.4-2.142h-2.173zm0-.714h2.656c.29 0 .483-.047.628-.19.145-.095.193-.286.193-.476 0-.191-.048-.381-.193-.476a1.08 1.08 0 00-.628-.191h-2.656v1.333z"
        transform="translate(-.584 -.221)"
      ></path>
      <path
        fill="url(#_Linear12)"
        fillRule="nonzero"
        d="M130.523 29.165v-2.523l-2.269-2.38h1.014l1.642 1.761 1.593-1.761h1.014l-2.269 2.38v2.523h-.725z"
        transform="translate(-.584 -.221)"
      ></path>
      <path
        fill="#4A4444"
        fillRule="nonzero"
        d="M70.551 16.359l5.988-11.092V.364l-8.643 15.995h2.655z"
        transform="translate(-.584 -.221)"
      ></path>
      <path
        fill="#4A4444"
        fillRule="nonzero"
        d="M85.182 16.359L76.539.364l-.097 4.713 6.085 11.282h2.655z"
        transform="translate(-.584 -.221)"
      ></path>
      <path
        fill="#4A4444"
        fillRule="nonzero"
        d="M72.531 10.361H80.305V12.313H72.531z"
        transform="translate(-.584 -.221)"
      ></path>
      <path
        fill="url(#_Linear13)"
        fillRule="nonzero"
        d="M41.917 29.165v-4.951h4.684v.714H42.69v1.238h2.559v.714H42.69v1.571h4.105v.714h-4.878z"
        transform="translate(-.584 -.221)"
      ></path>
      <path
        fill="url(#_Linear14)"
        fillRule="nonzero"
        d="M49.788 28.499l.387-.667c.338.238.724.381 1.11.524.386.143.773.19 1.159.19.435 0 .821-.095 1.062-.285.29-.191.387-.429.387-.667 0-.286-.145-.476-.387-.619-.241-.143-.627-.19-1.159-.238-.917 0-1.545-.095-1.883-.333-.338-.19-.531-.524-.531-.952 0-.429.193-.762.58-1 .386-.238.869-.381 1.496-.381.435 0 .821.048 1.256.191.386.095.773.285 1.159.523l-.386.619c-.29-.19-.628-.381-1.015-.476-.338-.095-.676-.19-1.014-.19-.386 0-.676.047-.917.19-.242.143-.338.286-.338.524 0 .238.096.381.338.476.241.095.628.143 1.207.143.773 0 1.4.143 1.787.381.386.285.628.666.628 1.19 0 .476-.194.857-.628 1.142-.387.286-.918.476-1.594.476a5.04 5.04 0 01-1.448-.19 5.986 5.986 0 01-1.256-.571z"
        transform="translate(-.584 -.221)"
      ></path>
      <path
        fill="url(#_Linear15)"
        fillRule="nonzero"
        d="M59.59 29.117v-4.236h-2.076v-.667h4.974v.667h-2.125v4.236h-.773z"
        transform="translate(-.584 -.221)"
      ></path>
      <path
        fill="url(#_Linear16)"
        fillRule="nonzero"
        d="M64.178 29.117l2.511-4.903h.482l2.56 4.903h-.821l-.628-1.237h-2.607l-.628 1.237h-.869zm1.786-1.904h1.932l-.966-1.856-.966 1.856z"
        transform="translate(-.584 -.221)"
      ></path>
      <path
        fill="url(#_Linear17)"
        fillRule="nonzero"
        d="M73.545 29.117v-4.236h-2.076v-.667h4.973v.667h-2.124v4.236h-.773z"
        transform="translate(-.584 -.221)"
      ></path>
      <path
        fill="url(#_Linear18)"
        fillRule="nonzero"
        d="M79.05 29.165v-4.951h4.684v.714h-3.911v1.238h2.559v.714h-2.559v1.571h4.104v.714H79.05z"
        transform="translate(-.584 -.221)"
      ></path>
      <path
        fill="url(#_Linear19)"
        fillRule="nonzero"
        d="M91.846 29.117l-1.69-4.903h.772l1.207 3.571 1.256-3.571h.628l1.255 3.571 1.207-3.571h.773l-1.69 4.903h-.58l-1.303-3.76-1.352 3.76h-.483z"
        transform="translate(-.584 -.221)"
      ></path>
      <path
        fill="url(#_Linear20)"
        fillRule="nonzero"
        d="M100.537 24.214H101.31V29.116999999999997H100.537z"
        transform="translate(-.584 -.221)"
      ></path>
      <path
        fill="url(#_Linear21)"
        fillRule="nonzero"
        d="M105.125 29.117v-4.903h.386l3.187 3.428v-3.428h.772v4.903h-.386l-3.139-3.427v3.427h-.82z"
        transform="translate(-.584 -.221)"
      ></path>
      <path
        fill="url(#_Linear22)"
        fillRule="nonzero"
        d="M112.995 29.165v-4.951h4.684v.714h-3.911v1.238h2.559v.714h-2.559v1.571h4.104v.714h-4.877z"
        transform="translate(-.584 -.221)"
      ></path>
      <path
        fill="url(#_Linear23)"
        fillRule="nonzero"
        d="M121.59 26.975v2.142h-.772v-4.903h3.428c.531 0 .918.143 1.207.381.29.238.435.571.435 1 0 .333-.097.666-.338.904-.242.238-.531.429-.869.476l1.4 2.142h-.917l-1.401-2.142h-2.173zm0-.714h2.656c.29 0 .483-.047.628-.19.145-.095.193-.286.193-.476 0-.191-.048-.381-.193-.476a1.08 1.08 0 00-.628-.191h-2.656v1.333z"
        transform="translate(-.584 -.221)"
      ></path>
      <path
        fill="url(#_Linear24)"
        fillRule="nonzero"
        d="M130.572 29.165v-2.523l-2.27-2.38h1.014l1.642 1.761 1.642-1.761h1.014l-2.27 2.38v2.523h-.772z"
        transform="translate(-.584 -.221)"
      ></path>
      <defs>
        <linearGradient
          id="_Linear1"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="translate(42.041 26.683) scale(91.5299)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#DABE68"></stop>
          <stop offset="0.07" stopColor="#C0A14F"></stop>
          <stop offset="0.21" stopColor="#92702A"></stop>
          <stop offset="0.23" stopColor="#9C7A30"></stop>
          <stop offset="0.32" stopColor="#BB9C4A"></stop>
          <stop offset="0.39" stopColor="#D1B45F"></stop>
          <stop offset="0.44" stopColor="#DABE68"></stop>
          <stop offset="0.52" stopColor="#C3A451"></stop>
          <stop offset="0.67" stopColor="#92702A"></stop>
          <stop offset="0.74" stopColor="#98762E"></stop>
          <stop offset="0.84" stopColor="#AA893B"></stop>
          <stop offset="0.95" stopColor="#C9AB58"></stop>
          <stop offset="1" stopColor="#DABE68"></stop>
        </linearGradient>
        <linearGradient
          id="_Linear2"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="translate(42.041 26.683) scale(91.5299)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#DABE68"></stop>
          <stop offset="0.07" stopColor="#C0A14F"></stop>
          <stop offset="0.21" stopColor="#92702A"></stop>
          <stop offset="0.23" stopColor="#9C7A30"></stop>
          <stop offset="0.32" stopColor="#BB9C4A"></stop>
          <stop offset="0.39" stopColor="#D1B45F"></stop>
          <stop offset="0.44" stopColor="#DABE68"></stop>
          <stop offset="0.52" stopColor="#C3A451"></stop>
          <stop offset="0.67" stopColor="#92702A"></stop>
          <stop offset="0.74" stopColor="#98762E"></stop>
          <stop offset="0.84" stopColor="#AA893B"></stop>
          <stop offset="0.95" stopColor="#C9AB58"></stop>
          <stop offset="1" stopColor="#DABE68"></stop>
        </linearGradient>
        <linearGradient
          id="_Linear3"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="translate(42.041 26.683) scale(91.5298)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#DABE68"></stop>
          <stop offset="0.07" stopColor="#C0A14F"></stop>
          <stop offset="0.21" stopColor="#92702A"></stop>
          <stop offset="0.23" stopColor="#9C7A30"></stop>
          <stop offset="0.32" stopColor="#BB9C4A"></stop>
          <stop offset="0.39" stopColor="#D1B45F"></stop>
          <stop offset="0.44" stopColor="#DABE68"></stop>
          <stop offset="0.52" stopColor="#C3A451"></stop>
          <stop offset="0.67" stopColor="#92702A"></stop>
          <stop offset="0.74" stopColor="#98762E"></stop>
          <stop offset="0.84" stopColor="#AA893B"></stop>
          <stop offset="0.95" stopColor="#C9AB58"></stop>
          <stop offset="1" stopColor="#DABE68"></stop>
        </linearGradient>
        <linearGradient
          id="_Linear4"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="translate(42.041 26.683) scale(91.5298)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#DABE68"></stop>
          <stop offset="0.07" stopColor="#C0A14F"></stop>
          <stop offset="0.21" stopColor="#92702A"></stop>
          <stop offset="0.23" stopColor="#9C7A30"></stop>
          <stop offset="0.32" stopColor="#BB9C4A"></stop>
          <stop offset="0.39" stopColor="#D1B45F"></stop>
          <stop offset="0.44" stopColor="#DABE68"></stop>
          <stop offset="0.52" stopColor="#C3A451"></stop>
          <stop offset="0.67" stopColor="#92702A"></stop>
          <stop offset="0.74" stopColor="#98762E"></stop>
          <stop offset="0.84" stopColor="#AA893B"></stop>
          <stop offset="0.95" stopColor="#C9AB58"></stop>
          <stop offset="1" stopColor="#DABE68"></stop>
        </linearGradient>
        <linearGradient
          id="_Linear5"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="translate(42.041 26.683) scale(91.5298)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#DABE68"></stop>
          <stop offset="0.07" stopColor="#C0A14F"></stop>
          <stop offset="0.21" stopColor="#92702A"></stop>
          <stop offset="0.23" stopColor="#9C7A30"></stop>
          <stop offset="0.32" stopColor="#BB9C4A"></stop>
          <stop offset="0.39" stopColor="#D1B45F"></stop>
          <stop offset="0.44" stopColor="#DABE68"></stop>
          <stop offset="0.52" stopColor="#C3A451"></stop>
          <stop offset="0.67" stopColor="#92702A"></stop>
          <stop offset="0.74" stopColor="#98762E"></stop>
          <stop offset="0.84" stopColor="#AA893B"></stop>
          <stop offset="0.95" stopColor="#C9AB58"></stop>
          <stop offset="1" stopColor="#DABE68"></stop>
        </linearGradient>
        <linearGradient
          id="_Linear6"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="translate(42.041 26.683) scale(91.5299)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#DABE68"></stop>
          <stop offset="0.07" stopColor="#C0A14F"></stop>
          <stop offset="0.21" stopColor="#92702A"></stop>
          <stop offset="0.23" stopColor="#9C7A30"></stop>
          <stop offset="0.32" stopColor="#BB9C4A"></stop>
          <stop offset="0.39" stopColor="#D1B45F"></stop>
          <stop offset="0.44" stopColor="#DABE68"></stop>
          <stop offset="0.52" stopColor="#C3A451"></stop>
          <stop offset="0.67" stopColor="#92702A"></stop>
          <stop offset="0.74" stopColor="#98762E"></stop>
          <stop offset="0.84" stopColor="#AA893B"></stop>
          <stop offset="0.95" stopColor="#C9AB58"></stop>
          <stop offset="1" stopColor="#DABE68"></stop>
        </linearGradient>
        <linearGradient
          id="_Linear7"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="translate(42.041 26.683) scale(91.5298)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#DABE68"></stop>
          <stop offset="0.07" stopColor="#C0A14F"></stop>
          <stop offset="0.21" stopColor="#92702A"></stop>
          <stop offset="0.23" stopColor="#9C7A30"></stop>
          <stop offset="0.32" stopColor="#BB9C4A"></stop>
          <stop offset="0.39" stopColor="#D1B45F"></stop>
          <stop offset="0.44" stopColor="#DABE68"></stop>
          <stop offset="0.52" stopColor="#C3A451"></stop>
          <stop offset="0.67" stopColor="#92702A"></stop>
          <stop offset="0.74" stopColor="#98762E"></stop>
          <stop offset="0.84" stopColor="#AA893B"></stop>
          <stop offset="0.95" stopColor="#C9AB58"></stop>
          <stop offset="1" stopColor="#DABE68"></stop>
        </linearGradient>
        <linearGradient
          id="_Linear8"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="translate(42.041 26.683) scale(91.5296)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#DABE68"></stop>
          <stop offset="0.07" stopColor="#C0A14F"></stop>
          <stop offset="0.21" stopColor="#92702A"></stop>
          <stop offset="0.23" stopColor="#9C7A30"></stop>
          <stop offset="0.32" stopColor="#BB9C4A"></stop>
          <stop offset="0.39" stopColor="#D1B45F"></stop>
          <stop offset="0.44" stopColor="#DABE68"></stop>
          <stop offset="0.52" stopColor="#C3A451"></stop>
          <stop offset="0.67" stopColor="#92702A"></stop>
          <stop offset="0.74" stopColor="#98762E"></stop>
          <stop offset="0.84" stopColor="#AA893B"></stop>
          <stop offset="0.95" stopColor="#C9AB58"></stop>
          <stop offset="1" stopColor="#DABE68"></stop>
        </linearGradient>
        <linearGradient
          id="_Linear9"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="translate(42.041 26.683) scale(91.5299)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#DABE68"></stop>
          <stop offset="0.07" stopColor="#C0A14F"></stop>
          <stop offset="0.21" stopColor="#92702A"></stop>
          <stop offset="0.23" stopColor="#9C7A30"></stop>
          <stop offset="0.32" stopColor="#BB9C4A"></stop>
          <stop offset="0.39" stopColor="#D1B45F"></stop>
          <stop offset="0.44" stopColor="#DABE68"></stop>
          <stop offset="0.52" stopColor="#C3A451"></stop>
          <stop offset="0.67" stopColor="#92702A"></stop>
          <stop offset="0.74" stopColor="#98762E"></stop>
          <stop offset="0.84" stopColor="#AA893B"></stop>
          <stop offset="0.95" stopColor="#C9AB58"></stop>
          <stop offset="1" stopColor="#DABE68"></stop>
        </linearGradient>
        <linearGradient
          id="_Linear10"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="translate(42.041 26.683) scale(91.5298)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#DABE68"></stop>
          <stop offset="0.07" stopColor="#C0A14F"></stop>
          <stop offset="0.21" stopColor="#92702A"></stop>
          <stop offset="0.23" stopColor="#9C7A30"></stop>
          <stop offset="0.32" stopColor="#BB9C4A"></stop>
          <stop offset="0.39" stopColor="#D1B45F"></stop>
          <stop offset="0.44" stopColor="#DABE68"></stop>
          <stop offset="0.52" stopColor="#C3A451"></stop>
          <stop offset="0.67" stopColor="#92702A"></stop>
          <stop offset="0.74" stopColor="#98762E"></stop>
          <stop offset="0.84" stopColor="#AA893B"></stop>
          <stop offset="0.95" stopColor="#C9AB58"></stop>
          <stop offset="1" stopColor="#DABE68"></stop>
        </linearGradient>
        <linearGradient
          id="_Linear11"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="translate(42.041 26.683) scale(91.53)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#DABE68"></stop>
          <stop offset="0.07" stopColor="#C0A14F"></stop>
          <stop offset="0.21" stopColor="#92702A"></stop>
          <stop offset="0.23" stopColor="#9C7A30"></stop>
          <stop offset="0.32" stopColor="#BB9C4A"></stop>
          <stop offset="0.39" stopColor="#D1B45F"></stop>
          <stop offset="0.44" stopColor="#DABE68"></stop>
          <stop offset="0.52" stopColor="#C3A451"></stop>
          <stop offset="0.67" stopColor="#92702A"></stop>
          <stop offset="0.74" stopColor="#98762E"></stop>
          <stop offset="0.84" stopColor="#AA893B"></stop>
          <stop offset="0.95" stopColor="#C9AB58"></stop>
          <stop offset="1" stopColor="#DABE68"></stop>
        </linearGradient>
        <linearGradient
          id="_Linear12"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="translate(42.041 26.683) scale(91.53)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#DABE68"></stop>
          <stop offset="0.07" stopColor="#C0A14F"></stop>
          <stop offset="0.21" stopColor="#92702A"></stop>
          <stop offset="0.23" stopColor="#9C7A30"></stop>
          <stop offset="0.32" stopColor="#BB9C4A"></stop>
          <stop offset="0.39" stopColor="#D1B45F"></stop>
          <stop offset="0.44" stopColor="#DABE68"></stop>
          <stop offset="0.52" stopColor="#C3A451"></stop>
          <stop offset="0.67" stopColor="#92702A"></stop>
          <stop offset="0.74" stopColor="#98762E"></stop>
          <stop offset="0.84" stopColor="#AA893B"></stop>
          <stop offset="0.95" stopColor="#C9AB58"></stop>
          <stop offset="1" stopColor="#DABE68"></stop>
        </linearGradient>
        <linearGradient
          id="_Linear13"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="translate(41.913 26.684) scale(91.6866)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#E1B762"></stop>
          <stop offset="0.08" stopColor="#C59849"></stop>
          <stop offset="0.21" stopColor="#996A28"></stop>
          <stop offset="0.23" stopColor="#A0712C"></stop>
          <stop offset="0.32" stopColor="#C09345"></stop>
          <stop offset="0.39" stopColor="#D6AB59"></stop>
          <stop offset="0.44" stopColor="#E1B762"></stop>
          <stop offset="0.52" stopColor="#C89B4C"></stop>
          <stop offset="0.67" stopColor="#996A28"></stop>
          <stop offset="0.74" stopColor="#9F702B"></stop>
          <stop offset="0.84" stopColor="#B18338"></stop>
          <stop offset="0.96" stopColor="#D1A655"></stop>
          <stop offset="1" stopColor="#E1B762"></stop>
        </linearGradient>
        <linearGradient
          id="_Linear14"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="translate(41.913 26.684) scale(91.6866)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#E1B762"></stop>
          <stop offset="0.08" stopColor="#C59849"></stop>
          <stop offset="0.21" stopColor="#996A28"></stop>
          <stop offset="0.23" stopColor="#A0712C"></stop>
          <stop offset="0.32" stopColor="#C09345"></stop>
          <stop offset="0.39" stopColor="#D6AB59"></stop>
          <stop offset="0.44" stopColor="#E1B762"></stop>
          <stop offset="0.52" stopColor="#C89B4C"></stop>
          <stop offset="0.67" stopColor="#996A28"></stop>
          <stop offset="0.74" stopColor="#9F702B"></stop>
          <stop offset="0.84" stopColor="#B18338"></stop>
          <stop offset="0.96" stopColor="#D1A655"></stop>
          <stop offset="1" stopColor="#E1B762"></stop>
        </linearGradient>
        <linearGradient
          id="_Linear15"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="translate(41.913 26.684) scale(91.6866)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#E1B762"></stop>
          <stop offset="0.08" stopColor="#C59849"></stop>
          <stop offset="0.21" stopColor="#996A28"></stop>
          <stop offset="0.23" stopColor="#A0712C"></stop>
          <stop offset="0.32" stopColor="#C09345"></stop>
          <stop offset="0.39" stopColor="#D6AB59"></stop>
          <stop offset="0.44" stopColor="#E1B762"></stop>
          <stop offset="0.52" stopColor="#C89B4C"></stop>
          <stop offset="0.67" stopColor="#996A28"></stop>
          <stop offset="0.74" stopColor="#9F702B"></stop>
          <stop offset="0.84" stopColor="#B18338"></stop>
          <stop offset="0.96" stopColor="#D1A655"></stop>
          <stop offset="1" stopColor="#E1B762"></stop>
        </linearGradient>
        <linearGradient
          id="_Linear16"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="translate(41.913 26.684) scale(91.6866)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#E1B762"></stop>
          <stop offset="0.08" stopColor="#C59849"></stop>
          <stop offset="0.21" stopColor="#996A28"></stop>
          <stop offset="0.23" stopColor="#A0712C"></stop>
          <stop offset="0.32" stopColor="#C09345"></stop>
          <stop offset="0.39" stopColor="#D6AB59"></stop>
          <stop offset="0.44" stopColor="#E1B762"></stop>
          <stop offset="0.52" stopColor="#C89B4C"></stop>
          <stop offset="0.67" stopColor="#996A28"></stop>
          <stop offset="0.74" stopColor="#9F702B"></stop>
          <stop offset="0.84" stopColor="#B18338"></stop>
          <stop offset="0.96" stopColor="#D1A655"></stop>
          <stop offset="1" stopColor="#E1B762"></stop>
        </linearGradient>
        <linearGradient
          id="_Linear17"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="translate(41.913 26.684) scale(91.6866)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#E1B762"></stop>
          <stop offset="0.08" stopColor="#C59849"></stop>
          <stop offset="0.21" stopColor="#996A28"></stop>
          <stop offset="0.23" stopColor="#A0712C"></stop>
          <stop offset="0.32" stopColor="#C09345"></stop>
          <stop offset="0.39" stopColor="#D6AB59"></stop>
          <stop offset="0.44" stopColor="#E1B762"></stop>
          <stop offset="0.52" stopColor="#C89B4C"></stop>
          <stop offset="0.67" stopColor="#996A28"></stop>
          <stop offset="0.74" stopColor="#9F702B"></stop>
          <stop offset="0.84" stopColor="#B18338"></stop>
          <stop offset="0.96" stopColor="#D1A655"></stop>
          <stop offset="1" stopColor="#E1B762"></stop>
        </linearGradient>
        <linearGradient
          id="_Linear18"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="translate(41.913 26.684) scale(91.6866)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#E1B762"></stop>
          <stop offset="0.08" stopColor="#C59849"></stop>
          <stop offset="0.21" stopColor="#996A28"></stop>
          <stop offset="0.23" stopColor="#A0712C"></stop>
          <stop offset="0.32" stopColor="#C09345"></stop>
          <stop offset="0.39" stopColor="#D6AB59"></stop>
          <stop offset="0.44" stopColor="#E1B762"></stop>
          <stop offset="0.52" stopColor="#C89B4C"></stop>
          <stop offset="0.67" stopColor="#996A28"></stop>
          <stop offset="0.74" stopColor="#9F702B"></stop>
          <stop offset="0.84" stopColor="#B18338"></stop>
          <stop offset="0.96" stopColor="#D1A655"></stop>
          <stop offset="1" stopColor="#E1B762"></stop>
        </linearGradient>
        <linearGradient
          id="_Linear19"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="translate(41.913 26.684) scale(91.6866)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#E1B762"></stop>
          <stop offset="0.08" stopColor="#C59849"></stop>
          <stop offset="0.21" stopColor="#996A28"></stop>
          <stop offset="0.23" stopColor="#A0712C"></stop>
          <stop offset="0.32" stopColor="#C09345"></stop>
          <stop offset="0.39" stopColor="#D6AB59"></stop>
          <stop offset="0.44" stopColor="#E1B762"></stop>
          <stop offset="0.52" stopColor="#C89B4C"></stop>
          <stop offset="0.67" stopColor="#996A28"></stop>
          <stop offset="0.74" stopColor="#9F702B"></stop>
          <stop offset="0.84" stopColor="#B18338"></stop>
          <stop offset="0.96" stopColor="#D1A655"></stop>
          <stop offset="1" stopColor="#E1B762"></stop>
        </linearGradient>
        <linearGradient
          id="_Linear20"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="translate(41.914 26.684) scale(91.6863)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#E1B762"></stop>
          <stop offset="0.08" stopColor="#C59849"></stop>
          <stop offset="0.21" stopColor="#996A28"></stop>
          <stop offset="0.23" stopColor="#A0712C"></stop>
          <stop offset="0.32" stopColor="#C09345"></stop>
          <stop offset="0.39" stopColor="#D6AB59"></stop>
          <stop offset="0.44" stopColor="#E1B762"></stop>
          <stop offset="0.52" stopColor="#C89B4C"></stop>
          <stop offset="0.67" stopColor="#996A28"></stop>
          <stop offset="0.74" stopColor="#9F702B"></stop>
          <stop offset="0.84" stopColor="#B18338"></stop>
          <stop offset="0.96" stopColor="#D1A655"></stop>
          <stop offset="1" stopColor="#E1B762"></stop>
        </linearGradient>
        <linearGradient
          id="_Linear21"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="translate(41.913 26.684) scale(91.6866)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#E1B762"></stop>
          <stop offset="0.08" stopColor="#C59849"></stop>
          <stop offset="0.21" stopColor="#996A28"></stop>
          <stop offset="0.23" stopColor="#A0712C"></stop>
          <stop offset="0.32" stopColor="#C09345"></stop>
          <stop offset="0.39" stopColor="#D6AB59"></stop>
          <stop offset="0.44" stopColor="#E1B762"></stop>
          <stop offset="0.52" stopColor="#C89B4C"></stop>
          <stop offset="0.67" stopColor="#996A28"></stop>
          <stop offset="0.74" stopColor="#9F702B"></stop>
          <stop offset="0.84" stopColor="#B18338"></stop>
          <stop offset="0.96" stopColor="#D1A655"></stop>
          <stop offset="1" stopColor="#E1B762"></stop>
        </linearGradient>
        <linearGradient
          id="_Linear22"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="translate(41.913 26.684) scale(91.6867)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#E1B762"></stop>
          <stop offset="0.08" stopColor="#C59849"></stop>
          <stop offset="0.21" stopColor="#996A28"></stop>
          <stop offset="0.23" stopColor="#A0712C"></stop>
          <stop offset="0.32" stopColor="#C09345"></stop>
          <stop offset="0.39" stopColor="#D6AB59"></stop>
          <stop offset="0.44" stopColor="#E1B762"></stop>
          <stop offset="0.52" stopColor="#C89B4C"></stop>
          <stop offset="0.67" stopColor="#996A28"></stop>
          <stop offset="0.74" stopColor="#9F702B"></stop>
          <stop offset="0.84" stopColor="#B18338"></stop>
          <stop offset="0.96" stopColor="#D1A655"></stop>
          <stop offset="1" stopColor="#E1B762"></stop>
        </linearGradient>
        <linearGradient
          id="_Linear23"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="translate(41.913 26.684) scale(91.6865)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#E1B762"></stop>
          <stop offset="0.08" stopColor="#C59849"></stop>
          <stop offset="0.21" stopColor="#996A28"></stop>
          <stop offset="0.23" stopColor="#A0712C"></stop>
          <stop offset="0.32" stopColor="#C09345"></stop>
          <stop offset="0.39" stopColor="#D6AB59"></stop>
          <stop offset="0.44" stopColor="#E1B762"></stop>
          <stop offset="0.52" stopColor="#C89B4C"></stop>
          <stop offset="0.67" stopColor="#996A28"></stop>
          <stop offset="0.74" stopColor="#9F702B"></stop>
          <stop offset="0.84" stopColor="#B18338"></stop>
          <stop offset="0.96" stopColor="#D1A655"></stop>
          <stop offset="1" stopColor="#E1B762"></stop>
        </linearGradient>
        <linearGradient
          id="_Linear24"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="translate(41.913 26.684) scale(91.6866)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#E1B762"></stop>
          <stop offset="0.08" stopColor="#C59849"></stop>
          <stop offset="0.21" stopColor="#996A28"></stop>
          <stop offset="0.23" stopColor="#A0712C"></stop>
          <stop offset="0.32" stopColor="#C09345"></stop>
          <stop offset="0.39" stopColor="#D6AB59"></stop>
          <stop offset="0.44" stopColor="#E1B762"></stop>
          <stop offset="0.52" stopColor="#C89B4C"></stop>
          <stop offset="0.67" stopColor="#996A28"></stop>
          <stop offset="0.74" stopColor="#9F702B"></stop>
          <stop offset="0.84" stopColor="#B18338"></stop>
          <stop offset="0.96" stopColor="#D1A655"></stop>
          <stop offset="1" stopColor="#E1B762"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};

export default WHLogo;
