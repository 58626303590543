import { useContext } from "react";
import { Navigate, Outlet, useSearchParams } from "react-router-dom";
import { AppContextInterface, AppCtx } from "./App";

export const TastingRouteWrapper = () => {
  /* const {
    configuration,
    appState,
    setAppState,
    tastingContent,
    loadConfiguration,
  } = useContext(AppCtx) as AppContextInterface;
  const [search] = useSearchParams();
  const serial = search.get("serial");
  const gtin = search.get("gtin");
  console.log("Got GTIN:", gtin);
  console.log("config:", configuration); */
  return <Outlet />;
};
