import { motion } from "framer-motion";
import * as React from "react";
import { useContext, useState } from "react";
import { Button } from "react-bootstrap";
import { XCircleFill } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { AppContextInterface, AppCtx } from "../App";
import TastingSummary from "../Components/TastingSummary";
import WHLogo from "../Components/WHLogo";

import { AppState } from "../models/app-state";
import { Wine } from "../models/wine";
import { Analytics } from "../utilities/Analytics";

interface WineMakerTastingNotePageProps {}

const WineMakerTastingNotePage: React.FunctionComponent<
  WineMakerTastingNotePageProps
> = (props) => {
  const navigate = useNavigate();
  const { configuration, tastingContent, appState, setAppState } = useContext(
    AppCtx
  ) as AppContextInterface;
  const SHARE_INTERFACE = configuration?.screens?.share_note;
  const NOTE_INTERFACE = configuration?.screens?.makers_note;
  const [showShareModal, setShowShareModal] = useState(false);
  const handleCloseShareModal = () => {
    setShowShareModal(false);
  };
  const selectedWine = tastingContent?.wines?.find(
    (wine: any) => wine?.id == appState?.tasting?.wine_id
  );

  const tastingNote = selectedWine?.makers_note;
  return (
    <motion.div
      initial={{ y: "100vh" }}
      animate={{ y: 0, transition: { duration: 0.15, type: "easeOut" } }}
      className="d-flex flex-column gap-3 px-3 align-items-center winemaker-tasting-note-page"
    >
      <header className="d-flex flex-row justify-content-between text-center py-3 mb-3 header">
        <span className="flex-grow-1">WINEMAKER'S NOTES</span>
        <XCircleFill
          className="close-button"
          onClick={() => {
            setAppState(
              (prev: AppState) =>
                ({
                  ...prev,
                  tasting: { ...prev.tasting, wine_id: 0 },
                } as AppState)
            );
            navigate("/select");
          }}
        />
      </header>
      <div className="mb-2 logo-container">
        <WHLogo />
      </div>

      <div className="d-flex flex-column align-items-center">
        <span className="mb-4 wine-name">{selectedWine?.name}</span>
        <a className="cta-button" href={selectedWine?.url}>
          Buy a bottle
        </a>
      </div>
      <span className="mx-4 px-2 section note">{tastingNote?.review}</span>
      {/* <div className="my-2 mx-1 winemaker-notes">Read winemaker notes...</div> */}

      <div className="section">
        <TastingSummary tasting={tastingNote} wine={selectedWine} />
      </div>

      <div className="d-flex flex-column align-items-center section about-winemaker">
        <div
          className="winemaker-image"
          style={{
            backgroundImage: `url(${NOTE_INTERFACE?.image_url})`,
          }}
        ></div>
        <div
          className="text-center px-1 copy"
          dangerouslySetInnerHTML={{
            __html: NOTE_INTERFACE?.body as string,
          }}
        ></div>
      </div>
      <div className="d-flex flex-row pt-4 pb-2 justify-content-around section join">
        <div className="text-center join-text">
          Did you know club members can get this wine shipped?
        </div>
        <Button
          className="cta-button"
          onClick={() => {
            Analytics.push("joined", {
              wine_name: selectedWine?.name || "",
            });
            window.location.href = configuration?.club || "";
          }}
        >
          JOIN THE CLUB
        </Button>
      </div>
      <div className="section"></div>
    </motion.div>
  );
};

export default WineMakerTastingNotePage;
