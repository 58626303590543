import { motion } from "framer-motion";
import * as React from "react";
import { useContext, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { XCircleFill } from "react-bootstrap-icons";
import { Link, useNavigate } from "react-router-dom";
import StarsRating from "react-star-rate";
import { AppContextInterface, AppCtx } from "../App";
import ShareNotes from "../Components/ShareNotes";
import TastingSummary from "../Components/TastingSummary";

import { AppState } from "../models/app-state";
import { Analytics } from "../utilities/Analytics";

interface TastingNotePageProps {}

const TastingNotePage: React.FunctionComponent<TastingNotePageProps> = (
  props
) => {
  const navigate = useNavigate();
  const { configuration, tastingContent, appState, setAppState } = useContext(
    AppCtx
  ) as AppContextInterface;
  const SHARE_INTERFACE = configuration?.screens?.share_note;
  const NOTE_INTERFACE = configuration?.screens?.view_note;
  const [showShareModal, setShowShareModal] = useState(false);
  const handleCloseShareModal = () => {
    setShowShareModal(false);
  };
  const selectedWine = tastingContent?.wines?.find(
    (wine: any) => wine?.id === appState?.tasting?.wine_id
  );

  const tastingNote = selectedWine?.tasting_note;
  return (
    <motion.div
      initial={{ y: "100vh" }}
      animate={{ y: 0, transition: { duration: 0.15, type: "easeOut" } }}
      className="d-flex flex-column gap-3 px-3 align-items-center tasting-note-page"
    >
      <header className="d-flex flex-row justify-content-between text-center py-3 mb-3 header">
        <span>A TASTING NOTE BY {appState?.customer?.name.toUpperCase()}</span>
        <XCircleFill
          className="close-button"
          onClick={() => {
            setAppState(
              (prev: AppState) =>
                ({
                  ...prev,
                  tasting: { wine_id: 0, accepted_terms: false },
                } as AppState)
            );
            navigate("/select");
          }}
        />
      </header>
      <StarsRating value={tastingNote?.stars} />
      <span className="text-center mx-4 note">{tastingNote?.review}</span>
      <div className="d-flex flex-column align-items-center mt-2">
        <span className="wine-name">{selectedWine?.name}</span>
        <a className="cta-button buy-button" href={selectedWine?.url}>
          Buy a bottle
        </a>
      </div>

      <div
        className="d-flex flex-row gap-2 align-items-center my-2 mx-1 px-1 py-2 winemaker-notes"
        onClick={() => {
          navigate("/winemaker-note");
          Analytics.push("makers_note_viewed", {
            wine_name: selectedWine?.name || "",
          });
        }}
      >
        <div
          className="winemaker-image"
          style={{
            backgroundImage: `url(${NOTE_INTERFACE?.image_url})`,
          }}
        ></div>
        <div
          className="text-center px-3 copy"
          dangerouslySetInnerHTML={{
            __html: NOTE_INTERFACE?.body as string,
          }}
        ></div>
      </div>
      <div className="d-flex flex-row pt-4 pb-2 justify-content-around section join">
        <div className="text-center join-text">
          Did you know club members can get this wine shipped?
        </div>
        <Button
          className="cta-button"
          onClick={() => {
            Analytics.push("joined", {
              wine_name: selectedWine?.name || "",
            });
            window.location.href = configuration?.club || "";
          }}
        >
          JOIN THE CLUB
        </Button>
      </div>

      <TastingSummary tasting={tastingNote} wine={selectedWine} />

      <div className="d-flex flex-column gap-2 section">
        <Button
          className="cta-button"
          onClick={() => {
            setShowShareModal(true);
          }}
        >
          SHARE MY TASTING NOTES
        </Button>
        <Link
          to="/aroma"
          onClick={() => {
            setAppState((prev) => ({
              ...prev,
              tasting: {
                ...selectedWine?.tasting_note,
                wine_id: selectedWine?.id,
              },
            }));
          }}
        >
          Redo this note
        </Link>
      </div>
      <Modal
        show={showShareModal}
        fullscreen={true}
        onHide={handleCloseShareModal}
        className="share-note-modal"
      >
        <Modal.Body>
          <ShareNotes
            allNotes={false}
            title={SHARE_INTERFACE?.title || ""}
            subtitle={SHARE_INTERFACE?.body || ""}
            handleClose={handleCloseShareModal}
          />
        </Modal.Body>
      </Modal>
    </motion.div>
  );
};

export default TastingNotePage;
