import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { EmailNotes } from "../Components/EmailNotes";
import { NoteEditor } from "../Components/NoteEditor";
import { RoomHeader } from "../Components/RoomHeader";
import { motion } from "framer-motion";
import { AppContextInterface, AppCtx } from "../App";
import { AppState } from "../models/app-state";
import useAxiosFunction from "../hooks/useAxiosFunction";
import axiosTasting from "../api/tasting";
import { TastingNote } from "../models/tasting-note";
import { TastingContent } from "../models/tasting-content";
import Message from "../Components/Message";
import { Analytics } from "../utilities/Analytics";
import NavigationButtons from "../Components/NavigationButtons";

export interface AddNotePageProps {}

export function AddNotePage(props: AddNotePageProps) {
  const navigate = useNavigate();
  const [saveTastingResponse, saveTastingError, loading, axiosFetch] =
    useAxiosFunction();
  const {
    configuration,
    appState,
    setAppState,
    tastingContent,
    setTastingContent,
    galloSession,
  } = useContext(AppCtx) as AppContextInterface;
  const NOTE_INTERFACE = configuration?.screens?.rating_comments;
  const SAVE_INTERFACE = configuration?.screens?.save_add;
  const SAVE_SUCCESS_INTERFACE = configuration?.screens?.save_success;
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [readyToSave, setReadyToSave] = useState(false);
  const selectedWine = tastingContent?.wines?.find(
    (wine: any) => wine?.id == appState?.tasting?.wine_id
  );
  const handleCloseMessage = () => {
    setShowMessageModal(false);
    navigate("/select");
  };
  console.log("APP STATE:", appState);
  const handleClose = (
    email: string = "",
    optIn?: boolean,
    termsAgree?: boolean
  ) => {
    console.log("handleClose:", termsAgree);
    optIn = !!optIn;
    termsAgree = !!termsAgree;
    setAppState((prev) => ({
      ...prev,
      customer: { ...prev.customer, email, sign_up_for_email_list: optIn },
      tasting: { ...prev.tasting, accepted_terms: termsAgree },
    }));
    Analytics.push("note_saved", {
      wine_name: selectedWine?.name || "",
    });
    optIn &&
      Analytics.push("sign-up", {
        wine_name: selectedWine?.name || "",
      });
    setReadyToSave(true);
  };
  const handleShow = () => {
    Analytics.push("comment_completed", {
      wine_name: selectedWine?.name || "",
    });
    if (appState?.customer?.email) {
      handleClose(
        appState?.customer?.email,
        appState?.customer?.sign_up_for_email_list,
        appState?.customer?.accepted_terms
      );
    } else {
      setShowNotesModal(true);
    }
  };

  useEffect(() => {
    if (readyToSave) {
      let tastingFormData = new FormData();
      tastingFormData.append("customer_cookie", galloSession.token);
      tastingFormData.append("serialNumber", galloSession.serial);
      appState.customer.email &&
        tastingFormData.append("customer[email]", appState.customer.email);
      tastingFormData.append("customer[name]", appState.customer.name);
      appState?.tasting?.accepted_terms &&
        tastingFormData.append(
          "tasting[accepted_terms]",
          appState.tasting.accepted_terms.toString()
        );
      tastingFormData.append(
        "customer[sign_up_for_email_list]",
        appState?.customer?.sign_up_for_email_list?.toString() || "false"
      );

      const tasting = appState?.tasting || {};
      Object.keys(tasting).forEach((key) => {
        let value = tasting[key as keyof TastingNote];
        console.log("Key:", key, value);
        if (value !== undefined || (typeof value == "number" && value !== 0)) {
          value = typeof value == "string" ? value : value.toString();
          tastingFormData.append(
            `tasting[tasting_notes_attributes][0][${key}]`,
            value
          );
        }
      });
      console.log("FORM:", tastingFormData.entries());

      axiosFetch({
        axiosInstance: axiosTasting,
        method: "POST",
        url: `?serialNumber=${galloSession.serial}&customer_cookie=${galloSession.token}`,
        body: tastingFormData,
        requestConfig: {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        },
      });
      setReadyToSave(false);
    }
  }, [readyToSave]);

  useEffect(() => {
    console.log("SAVE TASTING RESPONSE:", saveTastingResponse);
    if (Object.keys(saveTastingResponse).length > 0 && !saveTastingError) {
      setShowNotesModal(false);

      setAppState(
        (prev: AppState) =>
          ({
            ...prev,
            tasting: {},
          } as AppState)
      );
      setTastingContent((prev) => ({ ...prev, ...saveTastingResponse }));
      if (appState?.customer?.email) {
        setShowMessageModal(true);
      } else {
        handleCloseMessage();
      }
    }
  }, [saveTastingResponse]);

  return (
    <motion.div
      initial={{ y: "100vh" }}
      animate={{ y: 0, transition: { duration: 0.15, type: "easeOut" } }}
      exit={{ y: "100vh" }}
      className="d-flex flex-column text-center gap-3 align-items-center page-container add-note-page"
    >
      <h6 className="title">{NOTE_INTERFACE?.body}</h6>
      <NoteEditor
        note={appState?.tasting?.review || ""}
        rating={appState?.tasting?.stars || 0}
        updateNote={(note: string, rating: number) => {
          setAppState((prev) => ({
            ...prev,
            tasting: {
              ...prev.tasting,
              review: note || "",
              stars: rating || 0,
            },
          }));
        }}
      />
      <NavigationButtons
        nextLabel="NEXT"
        nextHandler={handleShow}
        skipHandler={() => {
          setAppState((prev) => ({
            ...prev,
            tasting: { ...prev.tasting, review: "", stars: 0 },
          }));
          handleShow();
        }}
      />

      <Modal
        show={showNotesModal}
        onHide={() => {
          handleClose();
        }}
        dialogClassName="email-notes-modal"
        backdrop="static"
        centered={true}
      >
        <Modal.Body>
          <EmailNotes
            closeHandler={handleClose}
            termsUrl={configuration?.terms || ""}
          />
        </Modal.Body>
      </Modal>
      <Modal
        show={showMessageModal}
        onHide={() => {
          handleCloseMessage();
        }}
        dialogClassName="email-notes-modal"
        backdrop="static"
        centered={true}
      >
        <Modal.Body>
          <Message
            title="Success"
            message={
              appState?.customer?.email
                ? SAVE_INTERFACE?.title || ""
                : SAVE_SUCCESS_INTERFACE?.title || ""
            }
            buttonLabel="CONTINUE TASTING"
            buttonAction={handleCloseMessage}
          />
        </Modal.Body>
      </Modal>
    </motion.div>
  );
}
