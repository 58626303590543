import axios from "axios";
declare const window: any;
const BASE_URL =
  process.env.REACT_APP_GQR_TASTING_BASE_URL || window["envConfig"]?.apiBaseUrl;

export default axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});
